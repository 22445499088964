.login-text-top{
margin-top:0.56rem;
}
.img-style{
    width:2.3rem;
    height: 2.3rem;
    align-items: center;
}

::placeholder {
    color: white;
  }

  ::-ms-input-placeholder { /* Edge 12 -18 */
    color: white;
  }