.modal-productdetails-header {
    position: relative;
    border-radius: 0.625rem 0.625rem 0rem 0rem;
    background: #7D8FA5;
    width: 37.5rem;
    height: 2.625rem;
}

.modal-productdetails-header-text {

    position: absolute;
    color: #FFF;
    font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 143.75% */
    letter-spacing: 0.03125rem;
    margin-top: 0.62rem;
    margin-left: 0.62rem;
}

.modal-productdetails-productname {
    margin-top: 1.5rem;
    margin-left: 3rem;
    position: absolute;
    color: #1F384C;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: bold;
    line-height: 1.4375rem;
    /* 164.286% */
    letter-spacing: 0.03125rem;
}

.modal-productdetails-header-icon {
    margin-left: 35rem;
    margin-top: 0.75rem;
    position: absolute;
    width: 1.125rem;
    height: 1.125rem;
    cursor: pointer;
}

.content-grid-product-display {
    display: grid
}

.header-flex-product-display {
    display: flex;
    flex-direction: row;
}

.header-flex-collectible-div {
    position: relative;
    width: 11rem;
    height: 2.75rem;
    border-radius: 0.625rem 0rem 0rem 0rem;
    border-right: 1px solid rgba(255, 255, 255, 0.20);
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    background: rgba(125, 143, 165);
    cursor: pointer;
}

.header-flex-collectible-div:hover {
    opacity: 0.2;

}

.collectible-header-text-display {
    position: absolute;
    color: #FFF;
    text-align: right;
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 191.667% */
    letter-spacing: 0.03125rem;
    margin-left: 1.94rem;
    margin-top: 0.69rem;

}

.collectible-header-icon-display {
    position: absolute;
    width: 0.64519rem;
    height: 0.75rem;
    margin-left: 0.81rem;
    margin-top: 1.06rem;
}

.albumns-header-text-display {
    position: absolute;
    color: #FFF;
    text-align: right;
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 191.667% */
    letter-spacing: 0.03125rem;
    margin-left: 1.94rem;
    margin-top: 0.69rem;

}

.albumns-header-icon-display {
    position: absolute;
    width: 0.64519rem;
    height: 0.75rem;
    margin-left: 0.81rem;
    margin-top: 1.06rem;
}

.header-flex-albumns-div {
    position: relative;
    width: 10rem;
    height: 2.75rem;
    border-right: 1px solid rgba(255, 255, 255, 0.20);
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    background: rgba(125, 143, 165);
    cursor: pointer;
}

.header-flex-albumns-div:hover {
    opacity: 0.2;

}

.webpage-header-text-display {
    position: absolute;
    color: #FFF;
    text-align: right;
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 191.667% */
    letter-spacing: 0.03125rem;
    margin-left: 1.94rem;
    margin-top: 0.69rem;

}

.webpage-header-icon-display {
    position: absolute;
    width: 0.64519rem;
    height: 0.75rem;
    margin-left: 0.81rem;
    margin-top: 1.06rem;
}

.header-flex-webpage-div {
    position: relative;
    width: 10rem;
    height: 2.75rem;
    border-radius: 0rem 0.625rem 0rem 0rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    background: rgba(125, 143, 165);
    cursor: pointer;

}

.header-flex-webpage-div:hover {
    opacity: 0.2;

}

.content-display-grid-products {
    display: grid;
}

.content-display-collectible-div {
    position: relative;
    width: 31rem;
    height: 3.625rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    background: rgba(125, 143, 165, 0.2);
}

.content-display-collectible-div-img {
    position: absolute;
    width: 2rem;
    height: 2rem;
    margin-left: 0.81rem;
    margin-top: 0.81rem;
}

.content-display-collectible-div-colllectiblename {
    position: absolute;
    color: #1F384C;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: bold;

    letter-spacing: 0.03125rem;
    margin-top: 0.56rem;
    margin-left: 3.38rem;

}

.content-display-Description-div {
    color: #FFF;
    font-family: SF Pro Display;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4375rem;
    /* 191.667% */
    letter-spacing: 0.03125rem;
    position: absolute;
    margin-left: 3.38rem;
    margin-top: 1.63rem;

}

.content-display-timetag-div {
    color: #FFF;
    font-family: SF Pro Display;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4375rem;
    /* 191.667% */
    letter-spacing: 0.03125rem;
    position: absolute;
    margin-left: 16.62rem;
    margin-top: 1.12rem;

}

.main-produtdisplay-div {
    /* margin-top:0.1rem */
    margin-top: 3rem;
    margin-left: 3rem;
}



.scrollview-div-flex {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* border-radius: 0rem 0rem 0.625rem 0.625rem;
            border-bottom: 1px solid rgba(255, 255, 255, 0.20); */

}

.scrollview-div-items {
    position: relative;
    width: 27.9375rem;
    height: 3.5rem;
    border-radius: 0.625rem;
    background-color: rgba(125, 143, 165, 0.3);
}

.scrollview-div-img {
    position: absolute;
    margin-top: 0.7rem;
    margin-left: 1rem;
    width: 2rem;
    height: 2rem;

}

/* .custom-scroll-panel {
                overflow-y: scroll;
            } */

/* .custom-scroll-panel::-webkit-scrollbar {
                width: 12px; 
            } */

/* .custom-scroll-panel::-webkit-scrollbar-thumb {
                background-color:  rgba(125,143,165,0.3); 
            } */

/* .custom-scroll-panel::-webkit-scrollbar-track {
                background-color: #f1f1f1; 
            } */
.scrollview-div-img:hover {

    width: 7rem;
    height: 7rem;

}

.scrollview-div-collectname {
    position: absolute;
    color: #1F384C;
    font-family: Roboto;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 164.286% */
    letter-spacing: 0.03125rem;
    margin-left: 5rem;
    margin-top: 0.4rem;

    /* margin-left: 1rem; */


}

.scrollview-div-collectdes {
    position: absolute;
    color: #1F384C;
    font-family: Roboto;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 164.286% */
    letter-spacing: 0.03125rem;
    margin-left: 5rem;
    margin-top: 1.8rem;

    /* margin-left: 1rem; */


}

.product-data-name-display {
    position: absolute;
    margin-left: 4rem;
    color: #1F384C;
    margin-top: 5rem;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: bold;
    line-height: 1.4375rem;
    /* 164.286% */
    letter-spacing: 0.03125rem;

}

.product-data-albumname-display {
    position: absolute;
    margin-left: 4rem;
    color: #1F384C;
    margin-top: 8rem;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: bold;
    line-height: 1.4375rem;
    /* 164.286% */
    letter-spacing: 0.03125rem;

}

.product-data-webame-display {
    position: absolute;
    margin-left: 4rem;
    color: #1F384C;
    margin-top: 11rem;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: bold;
    line-height: 1.4375rem;
    /* 164.286% */
    letter-spacing: 0.03125rem;

}

.collectible-header-display {
    position: absolute;
    margin-left: 4rem;
    color: #1F384C;
    margin-top: 14rem;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: bold;
    line-height: 1.4375rem;
    /* 164.286% */
    letter-spacing: 0.03125rem;

}

.product-data-collectible-display {
    position: absolute;
    margin-left: 4rem;
    color: #1F384C;
    margin-top: 17rem;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: bold;
    line-height: 1.4375rem;
    /* 164.286% */
    letter-spacing: 0.03125rem;

}

.data-display-collectible {
    position: absolute;
    width: 40rem;
    margin-left: 33rem;
}



.image-icon-products-products:hover {

    z-index: 1;
    width: 10rem;
    height: 10rem;
}

.image-icon-products-products {
    position: absolute;
    margin-left: 41rem;
    margin-top: 0.9rem;
    width: 2rem;
    height: 2rem;
}

.text-new-product {
    color: #000;
}

.delete-icon-products-products {
    position: absolute;
    margin-left: 31rem;
    margin-top: 0.75rem;
    width: 1.3rem;
    height: 1.5rem;
}

.data-icon-products-products {
    position: absolute;
    margin-left: 27rem;
    margin-top: 0.75rem;
    width: 1.3rem;
    height: 1.5rem;
    /* background-color: black; */
}

.data-icon-products-products:hover {
    cursor: pointer;
}

.music-icon-products-products {
    position: absolute;
    margin-left: 0.2rem;
    margin-top: 0.2rem;
    width: 0.8rem;
    height: 0.6rem;
}

.music-icon-products-webpages {
    position: absolute;
    margin-left: 0.2rem;
    margin-top: 0.2rem;
    width: 0.8rem;
    height: 0.6rem;
}

.music-icon-products-collect {
    position: absolute;
    margin-left: 0.2rem;
    margin-top: 0.2rem;
    width: 0.8rem;
    height: 0.6rem;
}

.music-icon-products-text {
    position: absolute;
    margin-left: 1.2rem;
    color: #FFF;
    font-family: Roboto;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0.1rem;

}

.webpage-icon-products-text {
    position: absolute;
    margin-left: 1.2rem;
    color: #FFF;
    font-family: Roboto;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0.1rem;

}

.webpage-icon-products-collect {
    position: absolute;
    margin-left: 1.2rem;
    color: #FFF;
    font-family: Roboto;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0.1rem;

}

.delete-icon-products-products:hover {
    cursor: pointer;
}

.header-grid-products {
    margin-top: 1rem;
    margin-left: 1.5rem;
    display: grid;
    grid-template-columns: 5fr 1fr;
    padding-right: 2rem;
}

.header-text-products {
    display: absolute;
    color: #FAFAFA;
    font-family: Roboto;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 127.778% */
    letter-spacing: 0.03125rem;
}

.icons-grid-header-products {
    display: grid;
    /* grid-template-columns: 2fr 1fr 1fr; */
    /* column-gap: 1rem; */

}

.button-div-header-products {
    margin-left: 8rem;
    display: absolute;
    width: 6.875rem;
    height: 1.875rem;
    background-color: #04CD18;
    border-radius: 0.24rem;
}

.button-text-div-products {
    display: absolute;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.75rem;
    /* 100% */
    letter-spacing: 0.03125rem;
    margin-left: 0.25rem;
    margin-top: 0.50rem;
    margin-right: 0.25rem;
    cursor: pointer;

}

.edit-button-div {
    width: 1.875rem;
    height: 1.875rem;

}

.content-div-products1 {
    padding-left: 1rem;
    padding-top: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
}

.content-grid-products {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;


}

.products-flex-box-products {

    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.products-item-products {
    position: relative;
    width: 33rem;
    height: 3.5rem;
    border-radius: 0.625rem;
    opacity: 1;
    background-color: #181A22;
}

.products-item:hover {
    opacity: 0.2;
}

.products-name-div-products {
    position: absolute;
    color: #FAFAFA;
    overflow: hidden;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: bold;
    line-height: 1.4375rem;
    /* 164.286% */
    letter-spacing: 0.03125rem;
    margin-top: 0.3rem;
    margin-left: 1rem;
    width: 20rem;



}

.products-date-div-collect {

    position: absolute;
    width: 2.1875rem;
    height: 0.9375rem;
    border-radius: 0.1875rem;
    background: #7D8FA5;
    margin-top: 2.25rem;
    margin-left: 7rem;
}

.products-date-div-products {

    position: absolute;
    width: 2.1875rem;
    height: 0.9375rem;
    border-radius: 0.1875rem;
    background: #7D8FA5;
    margin-top: 2.25rem;
    margin-left: 1rem;
}

.products-date-div-webpages {

    position: absolute;
    width: 2.1875rem;
    height: 0.9375rem;
    border-radius: 0.1875rem;
    background: #7D8FA5;
    margin-top: 2.25rem;
    margin-left: 4rem;
}

.products-webpage-div-products {
    position: absolute;
    color: #FFF;
    font-family: Roboto;
    font-size: 0.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4375rem;
    /* 191.667% */
    letter-spacing: 0.03125rem;
    margin-top: 0.3rem;
    margin-left: 20rem;
}

.products-collectible-div-products {
    position: absolute;
    color: #FFF;
    font-family: Roboto;
    font-size: 0.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4375rem;
    /* 191.667% */
    letter-spacing: 0.03125rem;
    margin-top: 1.56rem;
    margin-left: 20rem;
}

.tag-div-products {
    position: absolute;
    display: grid;
    /* margin-top: 1.69rem; */
    /* margin-left: 22.62rem; */
    grid-template-columns: 1fr 1fr;
    width: 2.1875rem;
    height: 0.9375rem;
    margin-left: 42rem;
    border-radius: 0.1875rem;
    background: #FFF;
    margin-top: 1rem;
    /* align-items: center; */
    /* justify-content: center; */
}

.products-icon-products {
    width: 0.5625rem;
    height: 0.5625rem;
    margin: 0.2rem;
    margin-left: 0.2rem;
}

.products-text-products {
    color: #050505;
    font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0.1rem;


}

.songs-flex-box-products {

    display: flex;
    flex-direction: column;
    gap: 0.2rem;

}

.songs-item-products {
    position: relative;
    border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    /* background: rgba(31, 56, 76, 0.20); */
    opacity: 1;
    background: #7D8FA5;
    width: 24rem;
    height: 3.625rem;


}

.songs-item-products:hover {
    opacity: 0.2;
}

.song-image-products {
    position: absolute;
    margin-left: 0.88rem;
    margin-top: 0.87rem;
    width: 1.875rem;
    height: 1.875rem;
}

.song-name-products {
    position: absolute;
    margin-left: 3.38rem;
    margin-top: 0.56rem;
    /* width: 4.75rem; */
    height: 1.4375rem;
    color: #FFF;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 164.286% */
    letter-spacing: 0.03125rem;
}

.artist-name-products {
    position: absolute;
    color: #FFF;
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4375rem;
    /* 191.667% */
    letter-spacing: 0.03125rem;
    /* width: 5.75rem; */
    height: 1.4375rem;
    margin-left: 3.38rem;
    margin-top: 1.62rem;

}

.time-tag-products {
    position: absolute;
    margin-left: 18rem;
    margin-top: 1.12rem;
    color: #FFF;
    text-align: right;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 191.667% */
    letter-spacing: 0.03125rem;
}

.settings-icon-products-products {
    position: absolute;
    margin-left: 29rem;
    margin-top: 0.75rem;
    width: 1.25rem;
    height: 1.5rem;
}

.settings-icon-products-products:hover {
    cursor: pointer;
}

.edit-icon-display-products {
    position: absolute;
    margin-left: 50rem;
    margin-top: 0.75rem;
    width: 1.25rem;
    height: 1.5rem;
}

.edit-icon-display:hover {
    cursor: pointer;
}

.music-player-products {
    position: relative;
    width: 20.5rem;
    height: 21rem;
    border-radius: 0.625rem;
    background: #7D8FA5;
}

.player-img-products {
    position: absolute;
    width: 20.5rem;
    height: 15rem;
    background: url("../team/playerimg.png"), lightgray 50% / cover no-repeat;
    border-radius: 0.625rem 0.625rem 0 0;
}

.player-left-products {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 0.87rem;
    margin-top: 17rem;
}

.player-middle-products {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 8.5rem;
    margin-top: 17rem;
}

.player-right-products {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 16.5rem;
    margin-top: 17rem;
}

.modal-main-div {
    position: relative;
    width: 25rem;
    height: 15rem;

    background: #FFF;



}

.modal-products-name {
    color: #1F384C;
    position: absolute;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 143.75% */
    letter-spacing: 0.03125rem;
    margin-top: 17.94rem;
    margin-left: 24.56rem;
}

.modal-input {
    position: absolute;
    width: 19rem;
    height: 2.5rem;
    margin-left: 2rem;
    margin-top: 3rem;
    border-radius: 0.625rem;
    border-width: 0;
    opacity: 0.6;
    background: #7D8FA5;
    color: #ffffff;
    border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 164.286% */
    letter-spacing: 0.03125rem;
}

.modal-input::placeholder {
    color: #ffffff;
}

.modal-img-div {
    position: absolute;
    width: 10rem;
    height: 7rem;
    border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
    margin-left: 13.5rem;
    margin-top: 1.25rem;
    border-style: solid;
    border-color: #050505;

}

.modal-button {
    position: absolute;
    width: 6.25rem;
    height: 2.5rem;
    border-radius: 0.625rem;
    background: #7D8FA5;
    color: #FFF;
    opacity: 0.6;
    text-align: center;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-width: 0;
    margin-top: 3rem;
    margin-left: 25rem;
}

.modal-button:hover {
    opacity: 2;
}

.modal-header {
    position: absolute;
    width: 37.5rem;
    height: 2.625rem;
    border-radius: 0.625rem 0.625rem 0rem 0rem;
    background: #7D8FA5;
}

.modal-header-create {
    position: absolute;
    width: 18.5rem;
    height: 2.625rem;
    border-radius: 0.625rem 0.625rem 0rem 0rem;
    background: #7D8FA5;
}

.modal-header-assign {
    position: absolute;
    width: 18.5rem;
    height: 2.625rem;
    border-radius: 0.625rem 0.625rem 0rem 0rem;
    background: #7D8FA5;
}

.modal-header-text {
    position: absolute;
    color: #f5f5f5;
    font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 143.75% */
    letter-spacing: 0.03125rem;
    margin-left: 1rem;
}

.modal-header-text-create {
    position: absolute;
    color: #f5f5f5;
    font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 143.75% */
    letter-spacing: 0.03125rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.modal-header-text-assign {
    position: absolute;
    color: #f5f5f5;
    font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 143.75% */
    letter-spacing: 0.03125rem;
    margin-left: 1rem;
    margin-top: 0.6rem;
}

.modal-input-name {
    margin-top: 12rem;
    margin-left: 14.5rem;
    position: absolute;
    color: #1F384C;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    /* font-weight: 500; */
    line-height: 1.4375rem;
    /* 143.75% */
    letter-spacing: 0.03125rem;
}

.modal-input-field {
    position: absolute;
    width: 19rem;
    height: 3rem;
    border-radius: 0.625rem;
    /* opacity: 0.1; */
    background: #E5E9ED;
    /* color: #ffffff; */
    margin-top: 15rem;
    margin-left: 9.5rem;
    border-width: 0rem;

}

/* .modal-input-field:placeholder{
                    color: #050505;
                } */
.modal-preview-img {
    position: absolute;

}

.modal-input-field1 {
    position: absolute;
    width: 19rem;
    height: 2rem;
    border-radius: 0.625rem;
    opacity: 0.2;
    background: #7D8FA5;
    margin-top: 14rem;
    margin-left: 9rem;
}

.modal-button-next {
    position: absolute;
    width: 6.25rem;
    height: 2.5rem;
    border-radius: 0.625rem;
    background: rgb(252, 252, 252);
    opacity: 0.9;
    margin-top: 24rem;
    margin-left: 15.5rem;
    border-width: 0rem;
    color: #7D8FA5;
    text-align: center;
    font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.modal-button-next:hover {
    color: #FFF;
    background: #7D8FA5;
}

.modal-button-next-assign {
    position: absolute;
    width: 6.25rem;
    height: 2.5rem;
    border-radius: 0.625rem;
    background: rgb(252, 252, 252);
    opacity: 0.9;
    margin-top: 14rem;
    margin-left: 10rem;
    border-width: 0rem;
    color: #7D8FA5;
    text-align: center;
    font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.modal-button-next-assign:hover {
    color: #FFF;
    background: #7D8FA5;
}

.modal-button-next-assign1 {
    position: absolute;
    width: 6.25rem;
    height: 2.5rem;
    border-radius: 0.625rem;
    background: rgb(252, 252, 252);
    opacity: 0.9;
    margin-top: 14rem;
    margin-left: 2rem;
    border-width: 0rem;
    color: #7D8FA5;
    text-align: center;
    font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.modal-button-next-assign1:hover {
    color: #FFF;
    background: #7D8FA5;
}

.modal-button-next2 {
    position: absolute;
    width: 6.25rem;
    height: 2.5rem;
    border-radius: 0.625rem;
    background: rgb(252, 252, 252);
    opacity: 0.9;
    margin-top: 28rem;
    margin-left: 15.5rem;
    border-width: 0rem;
    color: #7D8FA5;
    text-align: center;
    font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.modal-button-next2:hover {
    color: #FFF;
    background: #7D8FA5;
}

.modal-button-next2-create {
    position: absolute;
    width: 6.25rem;
    height: 2.5rem;
    border-radius: 0.625rem;
    background: rgb(252, 252, 252);
    opacity: 0.9;
    margin-top: 11rem;
    margin-left: 2rem;
    border-width: 0rem;
    color: #7D8FA5;
    text-align: center;
    font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.modal-button-next2-create:hover {
    color: #FFF;
    background: #7D8FA5;
}

.modal-upload-button {
    position: absolute;
    margin-top: 15rem;
    margin-left: 15rem;

}

.modal-input-name1 {
    margin-top: 5rem;
    margin-left: 5.5rem;
    position: absolute;
    color: #1F384C;
    font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    /* font-weight: 500; */
    line-height: 1.4375rem;
    /* 143.75% */
    letter-spacing: 0.03125rem;
}

.modal-input-name1-create {
    margin-top: 5rem;
    margin-left: 5.5rem;
    position: absolute;
    color: #FAFAFA;
    font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    /* font-weight: 500; */
    line-height: 1.4375rem;
    /* 143.75% */
    letter-spacing: 0.03125rem;
}

.modal-button-next1 {
    position: absolute;
    width: 6.25rem;
    height: 2.5rem;
    border-radius: 0.625rem;

    opacity: 0.9;
    margin-top: 11rem;
    margin-left: 6rem;
    border-width: 0rem;
    background: #ffffff;
    color: #7D8FA5;
    text-align: center;
    font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.modal-button-next1:hover {
    /* opacity: 0.2; */
    color: #FFF;
    background: #7D8FA5;
}

.modal-button-next1-create {
    position: absolute;
    width: 6.25rem;
    height: 2.5rem;
    border-radius: 0.625rem;

    opacity: 0.9;
    margin-top: 11rem;
    margin-left: 10rem;
    border-width: 0rem;
    background: #ffffff;
    color: #7D8FA5;
    text-align: center;
    font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.modal-button-next1-create:hover {
    /* opacity: 0.2; */
    color: #FFF;
    background: #7D8FA5;
}

.modal-input-file {
    position: absolute;
    width: 20rem;
    height: 12rem;
    border-radius: 0.625rem;
    opacity: 0;
    background: #7D8FA5;
    margin-left: 9rem;
    margin-top: 12rem;
    /* display: none; */
    /* display: none; */

}

.modal-input-file1 {
    position: absolute;
    width: 20rem;
    height: 12rem;
    border-radius: 0.625rem;
    opacity: 0.2;
    background: #7D8FA5;
    margin-left: 9rem;
    margin-top: 12rem;
    /* display: none; */
    /* display: none; */

}

.modal-input-upload {
    position: absolute;
    margin-left: 17.5rem;
    margin-top: 16rem;
}

.modal-input-upload-text {

    width: 8.25rem;
    position: absolute;
    margin-left: 15rem;
    margin-top: 20rem;
    color: #7D8FA5;
    text-align: center;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    /* 142.857% */
    letter-spacing: 0.03125rem;
}

.modal-input-upload-preview {
    position: absolute;
    width: 20rem;
    height: 12rem;
    margin-left: 9rem;
    margin-top: 12rem;
}

.spinner-products {
    margin-left: 25rem;
    margin-top: 10rem;
}

.modal-products-songupload {
    position: absolute;
    margin-top: 8rem;
    margin-left: 3rem;
    border: solid;
    border-radius: 0.2rem;
    /* opacity: 0.1; */
    background: #E5E9ED;
    width: 12rem;
    height: 1.8rem;
    outline-width: 0;
    border-width: 0rem;
}

.modal-products-songupload-create {
    position: absolute;
    margin-top: 8rem;
    margin-left: 2.8rem;
    border: solid;
    border-radius: 0.2rem;
    /* opacity: 0.1; */
    background: #292D34;
    width: 13rem;
    height: 1.8rem;
    outline-width: 0;
    border-width: 0rem;
    color: #FAFAFA;
}

.modal-input-field-pro {
    position: absolute;
    width: 14rem;
    height: 3rem;
    border-radius: 0.625rem;
    /* opacity: 0.2; */
    /* background: #7D8FA5; */
    background: #292D34;
    color: #faf7f7;
    margin-top: 5rem;
    margin-left: 2.5rem;
    border-width: 0rem;
    /* color: #050505; */
    color: #1F384C;
    z-index: 2;

    overflow: 'hidden';
    text-overflow: 'ellipsis';
}

.modal-input-field-pro1 {
    position: absolute;
    width: 14rem;
    height: 3rem;
    border-radius: 0.625rem;
    /* opacity: 0.2; */
    /* background: #7D8FA5; */
    background: #292D34;
    color: #faf7f7;
    margin-top: 9rem;
    margin-left: 2.5rem;
    border-width: 0rem;
    /* color: #050505; */
    color: #1F384C;

    overflow: 'hidden';
    text-overflow: 'ellipsis';
}

.productdeatils-albumicon-count-div {
    position: relative;
    width: 2.1875rem;
    height: 0.9375rem;
    border-radius: 0.1875rem;
    background: #7D8FA5;
    /* margin-left:3rem; */
    margin-top: 3.5rem;
}

.productdeatils-albumicon-count-musicicon {
    position: absolute;
    width: 1rem;
    height: 0.6rem;
    border-radius: 0.1875rem;
    margin-top: 0.2rem;
    margin-left: 0.2rem;
    /* margin-top:1rem; */
}

.productdeatils-albumicon-count {
    position: absolute;
    color: #FFF;
    font-family: SF Pro Display;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 1.2rem;
    margin-top: 0.1rem;
    /* margin-top:1rem; */
}

.div-icons-flexbox {
    display: flex;
    flex-direction: row;
    margin-left: 3rem;
    gap: 1rem;
}

.date-content-display-productdetails {
    position: absolute;
    color: #1F384C;
    text-align: right;
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 191.667% */
    letter-spacing: 0.03125rem;
    margin-left: 20rem;
}

.no-data-message {
    position: absolute;
    color: #1F384C;
    margin-left: 11rem;
    margin-top: 5rem;
}