.player_container
{
    width: 17rem;
    padding: 0 1rem;
    border: 1px solid rgb(141, 141, 141);
    border-radius: 0 0 8px 8px;
    color: rgb(218, 218, 218);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #7D8FA5;
    >*
    {
        padding: 0.9rem 0;
        color: rgb(202, 202, 202);
    }

    .title{
        font-size: inherit;
    }
    .navigation{
        width: 100%;
        height: 10px;

        .navigation_wrapper
        {
            min-width: 100%;
            background-color: #00000080;
            height: 5px;
            border-radius: 30px;
            cursor: pointer;
            
            .seek_bar{
                width: 0;
                height: 100%;
                background-color: white;
                border-radius: 30px;
            }

        }
    }
    .controls{
        font-size: inherit;
        display: flex;
        align-items: center;
        width: 15rem;

        .btn_action
        {
            font-size: 3rem;
            margin: 0 1rem;
            cursor: pointer;
            color: white;
            
            &:hover{
            color: white;
        }

        }
        
        .pp
        {
            font-size: 4rem;
            
        }
        
    }
}