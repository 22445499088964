@media only screen and (max-width: 1280px) {

    .song-back-button1:hover {
        cursor: pointer;
    }

    .song-back-button1 {
        margin-right: 4px;
    }

    .text-new-contacts {
        color: white;
    }

    .header-grid-contacts {
        margin-top: 1rem;
        margin-left: 1.5rem;
        display: grid;
        grid-template-columns: 5fr 1fr;
        padding-right: 2rem;
    }

    .header-text-contacts {
        display: absolute;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 127.778% */
        letter-spacing: 0.03125rem;
    }

    .icons-grid-header-contacts {
        display: grid;
        position: relative;
        grid-template-columns: 1fr;
        column-gap: 1rem;
    }

    .button-div-header-contacts {
        display: absolute;
        width: 6.875rem;
        height: 1.875rem;
        background-color: #04CD18;
        border-radius: 0.24rem;
        margin-left: 1.5rem;
        align-content: center;
    }

    .button-div-header-contacts:hover {
        cursor: pointer;
    }

    .button-text-div-contacts {
        display: absolute;
        color: #FFF;
        text-align: center;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 0.75rem;
        /* 100% */
        letter-spacing: 0.03125rem;
        margin-left: 0.25rem;
        margin-top: 0.50rem;
        margin-right: 0.25rem;
        cursor: pointer;

    }

    .edit-button-div {
        width: 1.875rem;
        height: 1.875rem;

    }

    .content-div-contacts {
        padding-left: 1rem;
        padding-top: 1.5rem;
        /* padding-right: 1rem; */
        padding-bottom: 1.5rem;
    }

    .content-grid-contacts {
        display: flex;
        /* grid-template-columns: 1fr 1fr; */
        /* column-gap: 1rem; */
    }

    .album-icon-albums {
        width: 0.5625rem;
        height: 0.5625rem;
        margin: 0.2rem;
        margin-left: 0.2rem;
        margin-top: 0.2rem;
    }

    .album-text-albums {
        color: #FFF;
        /* font-family: sf; */
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .tag-div-albums {
        position: absolute;
        display: flex;
        width: 2.1875rem;
        height: 0.9375rem;
        margin-left: 24.5rem;
        border-radius: 0.1875rem;
        margin-top: 1.4rem;
        background-color: #7D8FA5;
        justify-content: center;
        align-items: center;
    }

    .album-flex-box-contacts {

        display: flex;
        flex-direction: column;
        gap: 0.2rem;
    }

    .album-item-contacts {
        position: relative;
        width: 55rem;
        height: 3.5rem;
        border-radius: 0.625rem;
        opacity: 1;
        background: #7D8FA5;
    }

    .album-item:hover {
        opacity: 0.2;
    }

    .album-name-div-contacts {
        position: absolute;


        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 164.286% */
        letter-spacing: 0.03125rem;
        margin-top: 0.3rem;
        margin-left: 1rem;



    }

    .album-date-div-contacts {
        position: absolute;
        color: #FFF;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
        margin-top: 1.56rem;
        margin-left: 1rem;
    }

    .tag-div-contacts {
        position: absolute;
        display: grid;
        /* margin-top: 1.69rem; */
        /* margin-left: 22.62rem; */
        grid-template-columns: 1fr 1fr;
        width: 2.1875rem;
        height: 0.9375rem;
        margin-left: 42rem;
        border-radius: 0.1875rem;
        background: #FFF;
        margin-top: 1rem;
        /* align-items: center; */
        /* justify-content: center; */
    }

    .album-icon-contacts {
        width: 0.5625rem;
        height: 0.5625rem;
        margin: 0.2rem;
        margin-left: 0.2rem;
    }

    .album-text-contacts {
        color: #050505;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 0.1rem;


    }

    .songs-flex-box-contacts {

        display: flex;
        flex-direction: column;
        gap: 0.2rem;

    }

    .songs-item-contacts {

        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.20);
        opacity: 1;
        position: relative;
        background-color: #181A22;
        width: 30rem;
        height: 3.625rem;
        cursor: pointer;
    }

    .songs-item-contacts:hover {
        /* background-color: rgba(81, 117, 161, 0.3); */
    }

    .songs-container:hover{
        background-color: rgba(81, 117, 161, 0.3);
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;

    }

    .song-image-contacts {
        position: absolute;
        margin-left: 0.88rem;
        margin-top: 0.87rem;
        width: 1.875rem;
        height: 1.875rem;

    }

    .song-name-contacts {
        position: absolute;
        margin-left: 3.38rem;
        margin-top: 0.56rem;
        width: 18rem;
        height: 1.4375rem;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: bold;
        line-height: 1.4375rem;
        /* 164.286% */
        letter-spacing: 0.03125rem;
        overflow: hidden;
    }

    .artist-name-contacts {
        position: absolute;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
        width: 15rem;
        height: 1.4375rem;
        margin-left: 3.38rem;
        margin-top: 1.62rem;
        overflow: hidden;

    }

    .time-tag-contacts {
        position: absolute;
        margin-left: 23rem;
        margin-top: 1.12rem;
        color: #1F384C;
        text-align: right;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
    }

    .settings-icon-contacts {
        position: absolute;
        margin-left: 80px;
        width: 1.25rem;
        height: 1.5rem;
    }

    .settings-icon-contacts:hover {
        cursor: pointer;
    }

    .play-icon-contacts {
        position: absolute;
        margin-left: 1.8rem;
        margin-top: 1rem;
        width: 22px;
        height: 22px;
    }

    .play-icon-contacts:hover {
        cursor: pointer;
    }

    .edit-icon-display {
        position: absolute;
        margin-left: 50rem;
        margin-top: 0.75rem;
        width: 1.25rem;
        height: 1.5rem;
    }

    .edit-icon-display:hover {
        cursor: pointer;
    }

    .music-player-contacts {
        position: relative;
        width: 20.5rem;
        height: 21rem;
        border-radius: 0.625rem;
        background: #7D8FA5;
    }

    .player-img-contacts {
        position: absolute;
        width: 20.5rem;
        height: 15rem;
        background: url("./playerimg.png"), lightgray 50% / cover no-repeat;
        border-radius: 0.625rem 0.625rem 0 0;
    }

    .player-left-contacts {
        position: absolute;
        width: 2.5rem;
        height: 2.5rem;
        margin-left: 0.87rem;
        margin-top: 17rem;
    }

    .player-middle-contacts {
        position: absolute;
        width: 2.5rem;
        height: 2.5rem;
        margin-left: 8.5rem;
        margin-top: 17rem;
    }

    .player-right-contacts {
        position: absolute;
        width: 2.5rem;
        height: 2.5rem;
        margin-left: 16.5rem;
        margin-top: 17rem;
    }

    .modal-main-div {
        position: relative;
        width: 25rem;
        height: 15rem;

        background: #FFF;



    }

    .modal-album-name {
        color: #1F384C;
        position: absolute;
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 143.75% */
        letter-spacing: 0.03125rem;
        margin-top: 17.94rem;
        margin-left: 24.56rem;
    }

    .modal-input {
        position: absolute;
        width: 19rem;
        height: 2.5rem;
        margin-left: 2rem;
        margin-top: 3rem;
        border-radius: 0.625rem;
        border-width: 0;
        opacity: 0.6;
        background: #7D8FA5;
        color: #ffffff;
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 164.286% */
        letter-spacing: 0.03125rem;
    }

    .modal-input::placeholder {
        color: #ffffff;
    }

    .modal-img-div {
        position: absolute;
        width: 10rem;
        height: 7rem;
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
        margin-left: 13.5rem;
        margin-top: 1.25rem;
        border-style: solid;
        border-color: #050505;

    }

    .modal-button {
        position: absolute;
        width: 6.25rem;
        height: 2.5rem;
        border-radius: 0.625rem;
        background: #7D8FA5;
        color: #FFF;
        opacity: 0.6;
        text-align: center;
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-width: 0;
        margin-top: 3rem;
        margin-left: 25rem;
    }

    .modal-button:hover {
        opacity: 2;
    }
}

@media only screen and (min-width: 1281px) {

    .song-back-button1:hover {
        cursor: pointer;
    }

    .song-back-button1 {
        margin-right: 4px;
    }

    .text-new-contacts {
        color: white;
    }

    .header-grid-contacts {
        margin-top: 1rem;
        margin-left: 1.5rem;
        display: grid;
        grid-template-columns: 5fr 1fr;
        padding-right: 2rem;
    }

    .header-text-contacts {
        display: absolute;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 127.778% */
        letter-spacing: 0.03125rem;
    }

    .icons-grid-header-contacts {
        display: grid;
        position: relative;
        grid-template-columns: 1fr;
        column-gap: 1rem;
    }

    .button-div-header-contacts {
        display: absolute;
        width: 6.875rem;
        height: 1.875rem;
        background-color: #04CD18;
        border-radius: 0.24rem;
        margin-left: 1.5rem;
        align-content: center;
    }

    .button-div-header-contacts:hover {
        cursor: pointer;
    }

    .button-text-div-contacts {
        display: absolute;
        color: #FFF;
        text-align: center;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 0.75rem;
        /* 100% */
        letter-spacing: 0.03125rem;
        margin-left: 0.25rem;
        margin-top: 0.50rem;
        margin-right: 0.25rem;
        cursor: pointer;

    }

    .edit-button-div {
        width: 1.875rem;
        height: 1.875rem;

    }

    .content-div-contacts {
        padding-left: 1rem;
        padding-top: 1.5rem;
        padding-right: 1rem;
        padding-bottom: 1.5rem;
    }

    .content-grid-contacts {
        display: flex;
        /* grid-template-columns: 1fr 1fr 1fr; */
        /* column-gap: 1rem; */
    }

    .album-flex-box-contacts {

        display: flex;
        flex-direction: column;
        gap: 0.2rem;
    }

    .album-item-contacts {
        position: relative;
        width: 55rem;
        height: 3.5rem;
        border-radius: 0.625rem;
        opacity: 1;
        background: #7D8FA5;
    }

    .album-item:hover {
        opacity: 0.2;
    }

    .album-name-div-contacts {
        position: absolute;


        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 164.286% */
        letter-spacing: 0.03125rem;
        margin-top: 0.3rem;
        margin-left: 1rem;



    }

    .album-date-div-contacts {
        position: absolute;
        color: #FFF;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
        margin-top: 1.56rem;
        margin-left: 1rem;
    }

    .tag-div-contacts {
        position: absolute;
        display: grid;
        /* margin-top: 1.69rem; */
        /* margin-left: 22.62rem; */
        grid-template-columns: 1fr 1fr;
        width: 2.1875rem;
        height: 0.9375rem;
        margin-left: 42rem;
        border-radius: 0.1875rem;
        background: #FFF;
        margin-top: 1rem;
        /* align-items: center; */
        /* justify-content: center; */
    }

    .album-icon-contacts {
        width: 0.5625rem;
        height: 0.5625rem;
        margin: 0.2rem;
        margin-left: 0.2rem;
    }

    .album-text-contacts {
        color: #050505;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 0.1rem;


    }

    .songs-flex-box-contacts {

        display: flex;
        flex-direction: column;
        gap: 0.2rem;

    }

    .songs-item-contacts {
        
        position: relative;
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.20);
        background-color: #181A22;
        opacity: 1;
        width: 45rem;
        height: 3.625rem;
        cursor: pointer;

    }

    .songs-item-contacts:hover {
        /* background-color: rgba(81, 117, 161, 0.3); */
    }

    .songs-container:hover{
        background-color: rgba(81, 117, 161, 0.3);
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;

    }

    .song-image-contacts {
        position: absolute;
        margin-left: 0.88rem;
        margin-top: 0.87rem;
        width: 1.875rem;
        height: 1.875rem;
    }

    .song-name-contacts {
        position: absolute;
        margin-left: 3.38rem;
        margin-top: 0.56rem;
        width: 30rem;
        /* width: 4.75rem; */
        height: 1.4375rem;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: bold;
        line-height: 1.4375rem;
        /* 164.286% */
        letter-spacing: 0.03125rem;
        overflow: hidden;
    }

    .artist-name-contacts {
        position: absolute;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
        /* width: 5.75rem; */
        height: 1.4375rem;
        margin-left: 3.38rem;
        margin-top: 1.62rem;
        width: 26rem;
        overflow: hidden;

    }

    .time-tag-contacts {
        position: absolute;
        margin-left: 36rem;
        margin-top: 1.12rem;
        color: #1F384C;
        text-align: right;
        font-family: Roboto;

        font-size: 0.75rem;
        font-style: normal;
        font-weight: bold;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
    }

    .album-icon-albums {
        width: 0.5625rem;
        height: 0.5625rem;
        margin: 0.2rem;
        margin-left: 0.2rem;
        margin-top: 0.2rem;
    }

    .album-text-albums {
        color: #FFF;
        /* font-family: sf; */
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .tag-div-albums {
        position: absolute;
        display: flex;
        width: 2.1875rem;
        height: 0.9375rem;
        margin-left: 39rem;
        border-radius: 0.1875rem;
        margin-top: 1.4rem;
        background-color: #7D8FA5;
        justify-content: center;
        align-items: center;
    }

    .settings-icon-contacts {
        position: absolute;
        margin-left: 80px;
        width: 1.25rem;
        height: 1.5rem;
    }

    .settings-icon-contacts:hover {
        cursor: pointer;
    }

    .play-icon-contacts {
        position: absolute;
        margin-left: 2.0rem;
        margin-top: 1rem;
        width: 22px;
        height: 22px;
    }

    .play-icon-contacts:hover {
        cursor: pointer;
    }

    .edit-icon-display {
        position: absolute;
        margin-left: 50rem;
        margin-top: 0.75rem;
        width: 1.25rem;
        height: 1.5rem;
    }

    .edit-icon-display:hover {
        cursor: pointer;
    }

    .music-player-contacts {
        position: relative;
        width: 20.5rem;
        height: 21rem;
        border-radius: 0.625rem;
        background: #7D8FA5;
    }

    .player-img-contacts {
        position: absolute;
        width: 20.5rem;
        height: 15rem;
        background: url("./playerimg.png"), lightgray 50% / cover no-repeat;
        border-radius: 0.625rem 0.625rem 0 0;
    }

    .player-left-contacts {
        position: absolute;
        width: 2.5rem;
        height: 2.5rem;
        margin-left: 0.87rem;
        margin-top: 17rem;
    }

    .player-middle-contacts {
        position: absolute;
        width: 2.5rem;
        height: 2.5rem;
        margin-left: 8.5rem;
        margin-top: 17rem;
    }

    .player-right-contacts {
        position: absolute;
        width: 2.5rem;
        height: 2.5rem;
        margin-left: 16.5rem;
        margin-top: 17rem;
    }

    .modal-main-div {
        position: relative;
        width: 25rem;
        height: 15rem;

        background: #FFF;



    }

    .modal-album-name {
        color: #1F384C;
        position: absolute;
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 143.75% */
        letter-spacing: 0.03125rem;
        margin-top: 17.94rem;
        margin-left: 24.56rem;
    }

    .modal-input {
        position: absolute;
        width: 19rem;
        height: 2.5rem;
        margin-left: 2rem;
        margin-top: 3rem;
        border-radius: 0.625rem;
        border-width: 0;
        opacity: 0.6;
        background: #7D8FA5;
        color: #ffffff;
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 164.286% */
        letter-spacing: 0.03125rem;
    }

    .modal-input::placeholder {
        color: #ffffff;
    }

    .modal-img-div {
        position: absolute;
        width: 10rem;
        height: 7rem;
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
        margin-left: 13.5rem;
        margin-top: 1.25rem;
        border-style: solid;
        border-color: #050505;

    }

    .modal-button {
        position: absolute;
        width: 6.25rem;
        height: 2.5rem;
        border-radius: 0.625rem;
        background: #7D8FA5;
        color: #FFF;
        opacity: 0.6;
        text-align: center;
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-width: 0;
        margin-top: 3rem;
        margin-left: 25rem;
    }

    .modal-button:hover {
        opacity: 2;
    }
}