@media only screen and (max-width: 1280px) {
    .header-grid-dash {
        margin-top: 1rem;
        margin-left: 1.5rem;
        display: grid;
        grid-template-columns: 5fr 1fr;
        padding-right: 2rem;
    }

    .header-text-dash {
        display: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 127.778% */
        letter-spacing: 0.03125rem;
    }

    .content-div-dash {
        padding-left: 1.5rem;
        padding-top: 1.5rem;
        width: 55rem;
    }

    .content-grid-dash {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* column-gap: rem; */
        gap: 1.5rem;



    }

    .table-view{
        width:52rem;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    }

    .content-div-products {
        position: relative;
        width: 15rem;
        height: 15rem;
        border-radius: 0.625rem;
        background: #181A22;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    }

    .content-img {
        position: absolute;
        margin-left: 1.56rem;
        margin-top: 9.9rem;
        width: 1.5rem;
        height: 1.5rem;
    }

    .content-text {
        position: absolute;
        color: #FFF;
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 1.56rem;
        margin-top: 12rem;
    }

    .content-product-count {
        position: absolute;
        color: #FFF;
        text-align: right;
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 12.5rem;
        margin-top: 12rem;
    }

    .dash-flex-box {
        display: flex;
        flex-direction: column;
        gap: 1.56rem;
    }

    .dash-flex-item {
        position: relative;
        width: 17rem;
        height: 6.65rem;
        border-radius: 0.625rem;
        background: #181A22;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);

    }

    .dash-collectible-icon {
        position: absolute;
        width: 1.57569rem;
        height: 0.9rem;
        margin-left: 1.56rem;
        margin-top: 1.8rem;
    }

    .dash-coolectible-text {
        position: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 1.56rem;
        margin-top: 3.8rem
    }

    .dash-collectible-count {
        position: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 14rem;
        margin-top: 1.5rem;
    }

    .table-list{
        width: 100%;
    }

    .flex-empty-box {
        width: 18rem;
        height: 15rem;
        border-radius: 0.625rem;
        background: #FFF;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    }
}

@media only screen and (min-width: 1281px) {
    .header-grid-dash {
        margin-top: 1rem;
        margin-left: 1.5rem;
        display: grid;
        grid-template-columns: 5fr 1fr;
        padding-right: 2rem;
    }

    .header-text-dash {
        display: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 127.778% */
        letter-spacing: 0.03125rem;
    }

    .content-div-dash {
        padding-left: 1.5rem;
        padding-top: 1.5rem;
        width: 62.5rem;
    }

    .content-grid-dash {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* column-gap: rem; */
        gap: 1.5rem;



    }

    .content-div-products {
        position: relative;
        width: 20rem;
        height: 18rem;
        border-radius: 0.625rem;
        background: #181A22;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    }

    .content-img {
        position: absolute;
        margin-left: 1.56rem;
        margin-top: 11.98rem;
        width: 1.5rem;
        height: 1.5rem;
    }

    .content-text {
        position: absolute;
        color: #FFF;
        font-family: Roboto;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 1.56rem;
        margin-top: 14.5rem;
    }

    .content-product-count {
        position: absolute;
        color: #FFF;
        text-align: right;
        font-family: Roboto;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 15.5rem;
        margin-top: 14.5rem;
    }

    .dash-flex-box {
        display: flex;
        flex-direction: column;
        gap: 1.56rem;
    }

    .dash-flex-item {
        position: relative;
        width: 19rem;
        height: 8.2rem;
        border-radius: 0.625rem;
        background: #181A22;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);

    }

    .dash-collectible-icon {
        position: absolute;
        width: 1.57569rem;
        height: 1.2rem;
        margin-left: 1.56rem;
        margin-top: 2.2rem;
    }

    .dash-coolectible-text {
        position: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 1.56rem;
        margin-top: 4.8rem
    }

    .dash-collectible-count {
        position: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 16rem;
        margin-top: 1.95rem;
    }

    .table-view{
        width:61rem;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    }

    .table-list{
        width:100%;
    }

    .flex-empty-box {
        width: 26rem;
        height: 18rem;
        border-radius: 0.625rem;
        background: #FFF;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    }
}