.content_grid_product_display {
    display: grid;


}

.header_flex_product_display {
    display: flex;
    flex-direction: row;
}

.header_flex_collectible_div {
    position: relative;
    width: 6.83331rem;
    height: 2.75rem;
    border-radius: 0.625rem 0rem 0rem 0rem;
    border-right: 1px solid rgba(255, 255, 255, 0.20);
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    background: rgba(125, 143, 165);
}

.collectible_header_text_display {
    position: absolute;
    color: #FFF;
    text-align: right;
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    letter-spacing: 0.03125rem;
    margin-left: 1.94rem;
    margin-top: 0.69rem;

}

.collectible_header_icon_display {
    position: absolute;
    width: 0.64519rem;
    height: 0.75rem;
    margin-left: 0.81rem;
    margin-top: 1.06rem;
}

.albumns_header_text_display {
    position: absolute;
    color: #FFF;
    text-align: right;
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    letter-spacing: 0.03125rem;
    margin-left: 1.94rem;
    margin-top: 0.69rem;

}

.albumns_header_icon_display {
    position: absolute;
    width: 0.64519rem;
    height: 0.75rem;
    margin-left: 0.81rem;
    margin-top: 1.06rem;
}

.header_flex_albumns_div {
    position: relative;
    width: 6.83331rem;
    height: 2.75rem;
    border-right: 1px solid rgba(255, 255, 255, 0.20);
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    background: rgba(125, 143, 165);

}

.webpage_header_text_display {
    position: absolute;
    color: #FFF;
    text-align: right;
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    letter-spacing: 0.03125rem;
    margin-left: 1.94rem;
    margin-top: 0.69rem;

}

.webpage_header_icon_display {
    position: absolute;
    width: 0.64519rem;
    height: 0.75rem;
    margin-left: 0.81rem;
    margin-top: 1.06rem;
}

.header_flex_webpage_div {
    position: relative;
    width: 6.83331rem;
    height: 2.75rem;
    border-radius: 0rem 0.625rem 0rem 0rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    background: rgba(125, 143, 165);

}

.content_display_grid_products {
    display: grid;
}

.content_display_collectible_div {
    position: absolute;
    width: 20.5rem;
    height: 3.625rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    background: rgba(125, 143, 165);
}

.content_display_collectible_div_img {
    position: absolute;
    width: 2rem;
    height: 2rem;
    margin-left: 0.81rem;
    margin-top: 0.81rem;
}

.content_display_collectible_div_colllectiblename {
    position: absolute;
    color: #FFF;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;

    letter-spacing: 0.03125rem;
    margin-top: 0.56rem;
    margin-left: 3.38rem;

}

.content_display_Description_div {
    color: #FFF;
    font-family: SF Pro Display;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4375rem;
    letter-spacing: 0.03125rem;
    position: absolute;
    margin-left: 3.38rem;
    margin-top: 1.63rem;

}

.content_display_timetag_div {
    color: #FFF;
    font-family: SF Pro Display;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4375rem;
    letter-spacing: 0.03125rem;
    position: absolute;
    margin-left: 16.62rem;
    margin-top: 1.12rem;

}

.main_produtdisplay_div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 1.2rem;
    padding-top: 1.2rem;
}

.product_display_grid {
    position: relative;
    width: 27.9375rem;
    height: 6rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    background-color: #181A22;
}

.product_display_grid_name {
    position: absolute;
    color: #FAFAFA;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 164.286% */
    letter-spacing: 0.03125rem;
    margin-left: 1rem;
    margin-top: 1rem;
    overflow: hidden;
    width: 14rem;

}

.product_display_grid_timetag {
    position: absolute;
    color: #FAFAFA;
    text-align: right;
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 191.667% */
    letter-spacing: 0.03125rem;
    margin-left: 22rem;
    margin-top: 1rem;
    overflow: hidden;
    width: 4.5rem;

}

.product_tag_icon_grid {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: 3.5rem;
    margin-left: 1rem;
    gap: 1rem;

}

.product_display_grid_musicicontag {
    position: relative;
    width: 2.1875rem;
    height: 0.9375rem;
    flex-shrink: 0;
    border-radius: 0.1875rem;
    background: #7D8FA5;

}

.product_display_grid_icon {
    position: absolute;
    margin-left: 0.25rem;
    margin-top: 0.19rem;
    width: 0.8rem;
    height: 0.6rem;
}

.product_display_grid_text {
    position: absolute;
    margin-left: 1.13rem;
    margin-top: 0.1rem;
    color: #FFF;
    font-family: Roboto;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.content_display_div_grid {
    display: grid;
    position: relative;
    width: 25rem;

    border-radius: 0.625rem;
    background: #7D8FA5;
}

.content_display_header_flex {
    display: flex;
    flex-direction: row;
}

.content_display_header_items {
    position: relative;
    width: 8.32rem;
    height: 2.75rem;
    /* border-radius: 0.625rem 0rem 0rem 0rem; */
    border-right: 1px solid rgba(255, 255, 255, 0.20);
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    background: #181A22;
}

.content_display_header_items:hover {

    background:#292D34;
    cursor: pointer;
}

.content_display_header_items_icon {
    position: absolute;
    width: 0.64519rem;
    height: 0.75rem;
    margin-top: 1.06rem;
    margin-left: 1.2rem;


}

.content_display_header_items_name {
    position: absolute;
    color: #FFF;
    text-align: right;
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 191.667% */
    letter-spacing: 0.03125rem;
    margin-left: 2.7rem;
    margin-top: 0.69rem;
}

.content_display_dataitems {
    position: relative;
    width: 29.2em;
    height: 3.625rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    background-color: #292D34;
}

.content_display_dataitems_img {
    position: absolute;
    width: 2rem;
    height: 2rem;
    margin-left: 0.81rem;
    margin-top: 0.81rem;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.20); */


}

.content_display_dataitems_name {
    position: absolute;
    width: 82%;
    height: 1.5rem;
    margin-left: 0.81rem;
    margin-top: 0.4rem;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.20); */
    color: #FFF;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 164.286% */
    letter-spacing: 0.03125rem;
    margin-left: 4rem;
    overflow: hidden;
}

.content_display_dataitems_description {
    position: absolute;
    width: 15rem;
    height: 2rem;
    margin-left: 0.81rem;
    margin-top: 1.6rem;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.20); */
    color: #FFF;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 164.286% */
    letter-spacing: 0.03125rem;
    margin-left: 4rem;
    overflow: hidden;

}

.header_product_details {
    margin-top: 1rem;
    margin-left: 1.2rem;
    color: #FAFAFA;
    font-family: Roboto;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 127.778% */
    letter-spacing: 0.03125rem;
}

.no_data_message {
    position: absolute;
    color: #FFF;
    margin-left: 7.8rem;
    margin-top: 3.5rem;
}