.main_header_text_grid {
  display: grid;
  margin-top: 1rem;
  /* grid-template-columns: 5fr 1fr; */
}

.table_flex_main {
  display: flex;
  margin-left: 1.56rem;
  margin-top: 1.56rem;
  padding-bottom: 2rem;
}

.main_haeder_text {
  margin-top: 1rem;
  margin-left: 1.56rem;
  color: #FAFAFA;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4375rem;
  /* 127.778% */
  letter-spacing: 0.03125rem;
}

.main_header_create_button {
  width: 6.875rem;
  height: 1.875rem;
  background-color: #04CD18;

}

.custom_datatable_row {
  border-bottom: 1px solid #292D34;
  background: #181A22;
  color: #FAFAFA;
}

.modal_header_albums {
  position: absolute;
  width: 100%;
  height: 2.625rem;
  border-radius: 0.625rem 0.625rem 0rem 0rem;
  background: #7D8FA5;
}

.modal_header_text {
  position: absolute;
  color: #FFF;
  font-family: Roboto;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4375rem;
  /* 143.75% */
  letter-spacing: 0.03125rem;
  margin-top: 0.7rem;
  margin-left: 1rem;
}

.modal_close_icon {
  position: absolute;
  width: 1rem;
  height: 1.125rem;
  margin-left: 17rem;
  margin-top: 0.8rem;
}

.modal_input_label {
  margin-top: 5rem;
  margin-left: 7.5rem;
  position: absolute;
  color: #1F384C;
  font-family: Roboto;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4375rem;
  /* 143.75% */
  letter-spacing: 0.03125rem;
}

.modal_input_artistname {
  margin-top: 8.5rem;
  margin-left: 2.5rem;
  position: absolute;
  border-radius: 0.3rem;
  color: #FAFAFA;
  background: rgba(125, 143, 165, 0.2);
  width: 14rem;
  border-width: 0rem;
  outline-width: 0rem;
  height: 2.5rem;
  border-radius: 0.625rem;
  padding: 1rem;
  font-size: 1.1rem;
}

.modal_input_nextbutton {
  margin-top: 12rem;
  margin-left: 10rem;
  position: absolute;
  border-radius: 0.625rem;

  font-family: Roboto;
  font-size: 0.7rem;
  width: 4rem;
  height: 2rem;
  border-width: 0rem;
  color: #7D8FA5;
  background: #FFF;
}

.modal_input_nextbutton:hover {
  color: #ffffff;
  background: #7D8FA5;
}

.modal_input_cancelbutton {
  margin-top: 12rem;
  margin-left: 5rem;
  position: absolute;
  border-radius: 0.625rem;
  color: #7D8FA5;
  background: #fff;
  font-family: Roboto;
  font-size: 0.7rem;
  width: 4rem;
  height: 2rem;
  border-width: 0rem;
}

.modal_input_cancelbutton:hover {
  color: #ffffff;
  background: #7D8FA5;
}

.icon_tooltip {
  cursor: pointer;
}

.modal_dropdown1 {
  position: absolute;
  width: 14rem;
  height: 2.5rem;
  border-radius: 0.625rem;
  /* opacity: 0.2; */
  /* background: #7D8FA5; */
  background: #292D34;
  color: #FAFAFA;
  margin-top: 5rem;
  margin-left: 2.5rem;
  border-width: 0rem;
  /* color: #050505; */
  z-index: 2;
  overflow: 'hidden';
  text-overflow: 'ellipsis';
  align-items: center;
  
}