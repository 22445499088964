.modal-header-collectible {

  position: absolute;
  width: 19.75rem;
  height: 2rem;
  border-radius: 0.625rem 0.625rem 0rem 0rem;
  background: #7D8FA5;

}

.modal-header-text-collectible {
  position: absolute;
  color: #FAFAFA;
  font-family: Roboto;
  font-size: 0.8em;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.03125rem;
  margin-top: 0.5rem;
  margin-left: 1rem;
}

.text-new-collect {
  color: #000;
}

.header-grid-col {
  margin-top: 1rem;
  margin-left: 1.5rem;
  display: grid;
  grid-template-columns: 10fr 1fr;
  /* padding-right: 2rem; */
}

.header-text-col {
  display: absolute;
  color: #FAFAFA;
  font-family: SF Pro Display;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4375rem;
  /* 127.778% */
  letter-spacing: 0.03125rem;
}

.icons-grid-header-col {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 1rem;
}

.button-div-header-col {
  display: absolute;
  width: 6.875rem;
  height: 1.875rem;
  background-color: #04CD18;
  border-radius: 0.24rem;
}

.button-text-div-col {
  display: absolute;
  color: #FFF;
  text-align: center;
  font-family: SF Pro Display;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.75rem;
  letter-spacing: 0.03125rem;
  margin-left: 0.25rem;
  margin-top: 0.50rem;
  margin-right: 0.25rem;
  cursor: pointer;
}

.edit-button-div-col {
  width: 1.875rem;
  height: 1.875rem;

}

.content-div-col {
  padding-left: 1.5rem;
  ;
  padding-top: 1.5rem;
  padding-right: 1.5rem;
}

.content-grid-col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;


}

.album-flex-box-col {

  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.album-item-col {
  position: relative;
  width: 20rem;
  height: 3.5rem;
  border-radius: 0.625rem;
  opacity: 1;
  background: #7D8FA5;
}

.album-item-col:hover {
  opacity: 0.2;
}

.album-name-div-col {
  position: absolute;


  font-family: SF Pro Display;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4375rem;
  /* 164.286% */
  letter-spacing: 0.03125rem;
  margin-top: 0.3rem;
  margin-left: 1rem;



}

.album-date-div-col {
  position: absolute;
  color: #FFF;
  font-family: SF Pro Display;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4375rem;
  /* 191.667% */
  letter-spacing: 0.03125rem;
  margin-top: 1.56rem;
  margin-left: 1rem;
}

.tag-div-col {
  position: absolute;
  display: grid;
  /* margin-top: 1.69rem; */
  /* margin-left: 22.62rem; */
  grid-template-columns: 1fr 1fr;
  width: 2.1875rem;
  height: 0.9375rem;
  margin-left: 16rem;
  border-radius: 0.1875rem;
  background: #FFF;
  margin-top: 1rem;
  /* align-items: center; */
  /* justify-content: center; */
}

.album-icon-col {
  width: 0.5625rem;
  height: 0.5625rem;
  margin: 0.2rem;
  margin-left: 0.2rem;
}

.album-text-col {
  color: #050505;
  font-family: SF Pro Display;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0.1rem;


}

.songs-flex-box-col {

  display: flex;
  flex-direction: column;

}

.songs-item-col {
  position: relative;
  border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.20);
  /* background: rgba(31, 56, 76, 0.20); */
  opacity: 1;
  background: #7D8FA5;
  width: 20.5rem;
  height: 3.625rem;


}

.songs-item-col:hover {
  opacity: 0.2;
}

.song-image-col {
  position: absolute;
  margin-left: 0.88rem;
  margin-top: 0.87rem;
  width: 1.875rem;
  height: 1.875rem;
}

.song-name-col {
  position: absolute;
  margin-left: 3.38rem;
  margin-top: 0.56rem;
  width: 4.75rem;
  height: 1.4375rem;
  color: #FFF;
  font-family: SF Pro Display;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4375rem;
  /* 164.286% */
  letter-spacing: 0.03125rem;
}

.artist-name-col {
  position: absolute;
  color: #FFF;
  font-family: SF Pro Display;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4375rem;
  /* 191.667% */
  letter-spacing: 0.03125rem;
  width: 5.75rem;
  height: 1.4375rem;
  margin-left: 3.38rem;
  margin-top: 1.62rem;

}

.time-tag-col {
  position: absolute;
  margin-left: 16.62rem;
  margin-top: 1.12rem;
  color: #FFF;
  text-align: right;
  font-family: SF Pro Display;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4375rem;
  /* 191.667% */
  letter-spacing: 0.03125rem;
}

.settings-icon-col {
  position: absolute;
  margin-left: 18.94rem;
  margin-top: 1.38rem;
}

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.flex-box-collect {
  display: flex;
  flex-wrap: wrap;
  gap: 1.56rem;
  padding-bottom: 1rem;
}

.collectible-flex-item {
  position: relative;
  width: 10rem;
  height: 12rem;
  border-radius: 0.625rem;
  border: 2px solid #292D34;
  background: #181A22;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
}

.collectible-flex-img {
  position: absolute;
  width: 8rem;
  height: 8rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
  border-radius: 0.625rem;


}

.colletible-flex-name {
  position: absolute;
  color: #FAFAFA;
  font-family: SF Pro Display;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4375rem;
  /* 127.778% */
  letter-spacing: 0.03125rem;
  margin-left: 1.56rem;
  margin-top: 8.5rem;
  overflow: hidden;
  width: 7.5rem;
  height: 1.2rem;
}

.collectible-view-count {
  color: #7D8FA5;
  text-align: right;
  font-family: SF Pro Display;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 8.7rem;
  margin-left: 8rem;
  position: absolute;

}

.collectible-heart-icon {
  position: absolute;
  width: 1rem;
  height: 1rem;
  margin-left: 7.9rem;
  margin-top: 10.2rem;

}

.collectible-heart-icon:hover {
  cursor: pointer;
}

.collectible-settings-icon {
  position: absolute;
  width: 1rem;
  height: 1rem;
  margin-left: 7.9rem;
  margin-top: 10.2rem;

}

.collectible-settings-icon:hover {
  cursor: pointer;
}

.collectible-edit-icon {
  position: absolute;
  width: 1rem;
  height: 1rem;
  margin-left: 6.5rem;
  margin-top: 10.2rem;

}

.collectible-edit-icon:hover {
  cursor: pointer;
}

.collectible-description {
  position: absolute;
  color: #1F384C;
  font-family: SF Pro Display;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4375rem;
  /* 191.667% */
  letter-spacing: 0.03125rem;
  margin-left: 1.56rem;
  margin-top: 10rem;
  overflow: hidden;
  height: 1.5rem;
  width: 6rem;
}

.collectible-modal-input {
  position: absolute;
  width: 12rem;
  height: 2rem;
  border-radius: 0.2rem;
  background: #292D34;
  color: #FAFAFA;
  margin-top: 4rem;
  margin-left: 4rem;
  border-width: 0rem;
  outline-width: 0;
}

.collectible-modal-textarea {
  position: absolute;
  width: 12rem;
  height: 2rem;
  border-radius: 0.2rem;
  background: #292D34;
  color: #FAFAFA;
  margin-top: 8rem;
  margin-left: 4rem;
  border-width: 0rem;
  outline-width: 0;
}

.collectible-modal-image {
  position: absolute;
  width: 12rem;
  border-radius: 0.2rem;
  background: #292D34;
  margin-top: 12rem;
  margin-left: 4rem;
  border-width: 0rem;
  color: #FAFAFA;
}

.collectible-modal-button-create {
  width: 5rem;
  height: 2rem;
  position: absolute;
  border-radius: 0.625rem;
  background: #ffff;
  border-radius: 0.625rem;
  border-width: 0rem;
  margin-top: 15rem;
  margin-left: 11rem;

}

.collectible-modal-button-create:hover {

  background: #7D8FA5;
  color: #fff;
}

.collectible-modal-button-cancel {
  width: 5rem;
  height: 2rem;
  position: absolute;
  border-radius: 0.625rem;
  background: #ffff;
  border-radius: 0.625rem;
  border-width: 0rem;
  margin-top: 15rem;
  margin-left: 4rem;

}

.collectible-modal-button-cancel:hover {

  background: #7D8FA5;
  color: #fff;
}

.collectible-modal-input1 {
  position: absolute;
  width: 12rem;
  height: 2rem;
  border-radius: 0.2rem;
  background: #292D34;
  margin-top: 4rem;
  margin-left: 4rem;
  border-width: 0rem;
  outline-width: 0;
  color: #FAFAFA;
}

.collectible-modal-textarea1 {
  position: absolute;
  width: 12rem;
  height: 2rem;
  border-radius: 0.2rem;
  background: #292D34;
  color: #FAFAFA;
  margin-top: 8rem;
  margin-left: 4rem;
  border-width: 0rem;
  outline-width: 0;
}

.collectible-modal-image1 {
  position: absolute;
  width: 12rem;

  border-radius: 0.2rem;
  background: #292D34;
  margin-top: 20rem;
  margin-left: 4rem;
  border-width: 0rem;
  color: #FAFAFA;

}

.collectible-modal-button-create1 {
  width: 5rem;
  height: 2rem;
  position: absolute;
  border-radius: 0.625rem;
  background: #ffff;
  border-radius: 0.625rem;
  border-width: 0rem;
  margin-top: 24rem;
  margin-left: 11rem;

}

.collectible-modal-button-create1:hover {

  background: #7D8FA5;
  color: #fff;
}

.collectible-modal-button-cancel1 {
  width: 5rem;
  height: 2rem;
  position: absolute;
  border-radius: 0.625rem;
  background: #ffff;
  border-radius: 0.625rem;
  border-width: 0rem;
  margin-top: 24rem;
  margin-left: 4rem;

}

.collectible-modal-button-cancel1:hover {

  background: #7D8FA5;
  color: #fff;
}

.collectible-modal-img-preview {
  position: absolute;
  width: 7rem;
  height: 7rem;
  margin-top: 11.5rem;
  margin-left: 6.5rem;
}

.collectible-modal-input2 {
  position: absolute;
  width: 12rem;
  height: 2rem;
  border-radius: 0.2rem;
  background: #292D34;
  margin-top: 4rem;
  margin-left: 4rem;
  border-width: 0rem;
  outline-width: 0;
  pointer-events: none;
  color: #FAFAFA;
}

.collectible-modal-input2-label {
  position: absolute;
  width: 12rem;
  height: 2rem;
  border-radius: 0.2rem;
  margin-top: 2.7rem;
  margin-left: 4rem;
  border-width: 0rem;
  outline-width: 0;
  pointer-events: none;
  color: #FAFAFA;
  font-weight: bold;
}

.collectible-modal-textarea2 {
  position: absolute;
  width: 12rem;
  height: 8rem;
  border-radius: 0.2rem;
  background: #292D34;
  color: #FAFAFA;
  margin-top: 16rem;
  margin-left: 4rem;
  border-width: 0rem;
  outline-width: 0;
  resize: none;
  pointer-events: none
}

.collectible-modal-textarea2-label {
  position: absolute;
  width: 12rem;
  height: 8rem;
  border-radius: 0.2rem;
  color: #FAFAFA;
  margin-top: 14.7rem;
  margin-left: 4rem;
  border-width: 0rem;
  outline-width: 0;
  resize: none;
  pointer-events: none;
  font-weight: bold;
}

.collectible-modal-img-preview1 {
  position: absolute;
  width: 7rem;
  height: 7rem;
  margin-top: 6.8rem;
  margin-left: 6.5rem;
}

.collectible-modal-button-cancel2 {
  width: 5rem;
  height: 2rem;
  position: absolute;
  border-radius: 0.625rem;
  background: #ffff;
  border-radius: 0.625rem;
  border-width: 0rem;
  margin-top: 25rem;
  margin-left: 7rem;

}

.collectible-modal-button-cancel2:hover {

  background: #7D8FA5;
  color: #fff;
}