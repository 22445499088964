.overlay__background {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 9;
  }
  
  .overlay__container {
    background-color: white;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 10;
    /* padding: 30px; */
    width: fit-content;
    height: fit-content;
  }
  
  .overlay__controls {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 35rem;
height: 2.625rem;
background: #7D8FA5; 
padding-right: 1rem;
  }
  .overlay-maintext{
    color: #FFF;

font-family: Roboto;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: 1.4375rem; /* 143.75% */
letter-spacing: 0.03125rem;
  }
  .overlay__close:after {
    display: inline-block;
    content: "\00d7";
  }
  
  .overlay__close {
    border: none;
    background-color: transparent;
    font-size: 36px;
    cursor: pointer;
  }