@media only screen and (max-width: 1280px) {
  .header_grid_dash {
    margin-top: 1rem;
    margin-left: 1.5rem;
    display: grid;
    grid-template-columns: 5fr 1fr;
    padding-right: 2rem;
  }

  ::placeholder {
    color: white;
  }

  .white-text {
    color: white;
  }

  .header_text_dash {
    display: absolute;
    color: #FAFAFA;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 127.778% */
    letter-spacing: 0.03125rem;
  }

  .content_div_dash {
    padding-left: 1.5rem;
    padding-top: 1.5rem;
  }

  .content_div_flex {
    display: flex;

    /* grid-template-columns:1fr 1fr 1fr 1fr ; */
    flex-direction: row;
    gap: 1.5rem;
    position: relative;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .content_div_flex1 {
    /* grid-template-columns:1fr 1fr 1fr 1fr ; */
    flex-direction: row;
    gap: 1.5rem;
    position: relative;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .content_div_grid_items {
    position: relative;
    width: 50%;
    height: 7rem;
    border-radius: 0.625rem;
    background: #181a22;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
  }

  .content_div_grid_itemsdrop {
    position: relative;
    width: 50%;
    height: 7rem;
    border-radius: 0.625rem;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
  }

  .content_div_grid_items1 {
    position: relative;
    width: 46.5%;
    height: 7rem;
    border-radius: 0.625rem;
    background: #FFF;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
  }

  .content_div_grid_items_text {
    position: absolute;
    color: #fafafa;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5rem;
    margin-left: 1rem;
  }

  .content_div_grid_items_textdrop {
    position: absolute;
    color: #7D8FA5;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5rem;
    margin-left: 1rem;
  }

  .content_div_grid_items_count {
    position: relative;
    color: #fafafa;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-top: 5rem;
    margin-right: 1rem;
    display: flex;
    justify-content: flex-end
  }

  .content_div_grid_items_countdrop {
    position: relative;
    color: #7D8FA5;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-top: 5rem;
    margin-right: 1rem;
    display: flex;
    justify-content: flex-end
  }

  .content_div_grid_items_img {
    position: absolute;
    color: #fafafa;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 3rem;
    margin-left: 1rem;
  }

  .content_div_grid_items_imgdrop {
    position: absolute;
    color: #7D8FA5;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 3rem;
    margin-left: 1rem;
  }
}

@media only screen and (min-width: 1281px) {
  .header_grid_dash {
    margin-top: 1rem;
    margin-left: 1.5rem;
    display: grid;
    grid-template-columns: 5fr 1fr;
    padding-right: 2rem;
  }

  ::placeholder {
    color: white;
  }

  .white-text {
    color: white;
  }

  .header_text_dash {
    display: absolute;
    color: #FAFAFA;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 127.778% */
    letter-spacing: 0.03125rem;
  }

  .content_div_dash {
    padding-left: 1.5rem;
    ;
    padding-top: 1.5rem;
  }

  .content_div_flex {
    display: flex;

    /* grid-template-columns:1fr 1fr 1fr 1fr ; */
    flex-direction: row;
    gap: 1.5rem;
    position: relative;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .content_div_flex1 {
    /* grid-template-columns:1fr 1fr 1fr 1fr ; */
    flex-direction: row;
    gap: 1.5rem;
    position: relative;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .content_div_grid_items {
    position: relative;
    width: 50%;
    height: 7rem;
    border-radius: 0.625rem;
    background: #181a22;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
  }

  .content_div_grid_itemsdrop {
    position: relative;
    width: 50%;
    height: 7rem;
    border-radius: 0.625rem;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
  }

  .content_div_grid_items1 {
    position: relative;
    width: 47.7%;
    height: 7rem;
    border-radius: 0.625rem;
    background: #FFF;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
  }

  .artist_content_div_flex {
    display: flex;
    /* grid-template-columns:1fr 1fr 1fr 1fr ; */
    flex-direction: row;
    gap: 1rem;
    position: relative;
    margin-bottom: 1.5rem;

  }

  /* .artist_content_div_grid_items {
   position: relative;
   width: 50%;
   height: 7rem;
   border-radius: 0.625rem;
   background: #FFF;
   box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
 } */

  .content_div_grid_items_text {
    position: absolute;
    color: #fafafa;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5rem;
    margin-left: 1rem;
  }

  .content_div_grid_items_textdrop {
    position: absolute;
    color: #7D8FA5;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5rem;
    margin-left: 1rem;
  }

  .content_div_grid_items_count {
    position: relative;
    color: #fafafa;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-top: 5rem;
    margin-right: 1rem;
    display: flex;
    justify-content: flex-end
  }

  .content_div_grid_items_countdrop {
    position: relative;
    color: #7D8FA5;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-top: 5rem;
    margin-right: 1rem;
    display: flex;
    justify-content: flex-end
  }

  .content_div_grid_items_img {
    position: absolute;
    color: #fafafa;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 3rem;
    margin-left: 1rem;
  }

  .content_div_grid_items_imgdrop {
    position: absolute;
    color: #7D8FA5;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 3rem;
    margin-left: 1rem;
  }
}