@media only screen and (max-width: 1280px) {
    .album-loader-spinner {

        margin-left: 8rem;
        margin-top: 5.5rem;
    }

    .img-upload-preview-albums {
        position: absolute;
        width: 8rem;
        height: 8rem;
        margin-left: 5.5rem;
        margin-top: 8rem;
    }

    .modal-album-updatetext {
        position: absolute;
        margin-top: 5.5rem;
        margin-left: 3.5rem;
        border: solid;
        border-radius: 0.2rem;

        background: #E5E9ED;
        width: 12rem;
        border-width: 0rem;
        height: 1.7rem;
        outline-width: 0;
    }

    .modal-album-imageupdate {
        position: absolute;
        margin-top: 17rem;
        margin-left: 3.5rem;
        border: solid;
        background: #292D34;
        color: #FAFAFA;
        border-width: 0rem;
        width: 12rem;
        outline-width: 0;
    }

    .arrow-icon-albumns {
        position: absolute;
        margin-left: 33.5rem;
        margin-top: 1.45rem;
        width: 1rem;
        height: 0.7rem;
        cursor: pointer;
    }

    .custom-dialog {
        width: 30%;
        height: auto;

    }

    .colour-text-album {
        color: #000;
    }

    .header-grid-albums {
        margin-top: 1rem;
        margin-left: 1.5rem;
        display: grid;
        grid-template-columns: 5fr 1fr;
        padding-right: 2rem;
    }

    .modal-new-create-img-album {
        position: absolute;
        margin-top: 16rem;
        margin-left: 12rem;
        background: #E5E9ED;
        color: #000;
    }

    .header-text-albums {
        display: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 127.778% */
        letter-spacing: 0.03125rem;
    }

    .icons-grid-header-albums {
        display: grid;
        /* grid-template-columns: 2fr 1fr 1fr; */
        column-gap: 1rem;
    }

    .button-div-header-albums {
        margin-left: 3rem;
        display: absolute;
        width: 8rem;
        height: 1.875rem;
        background-color: #04CD18;
        border-radius: 0.24rem;
    }

    .button-text-div-albums {
        display: absolute;
        color: #FFF;
        text-align: center;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 0.75rem;
        /* 100% */
        letter-spacing: 0.03125rem;
        margin-left: 0.25rem;
        margin-top: 0.50rem;
        margin-right: 0.25rem;
        cursor: pointer;

    }

    .edit-button-div {
        width: 1.875rem;
        height: 1.875rem;

    }

    .content-div-albums {
        padding-left: 1rem;
        padding-top: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .content-grid-albums {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 1rem;


    }

    .album-flex-box-albums {

        display: flex;
        flex-direction: column;
        gap: 0.2rem;
    }

    .album-item-albums {
        position: relative;
        width: 35rem;
        height: 3.5rem;
        border-radius: 0.625rem;
        /* opacity: 0.5; */
        /* background: #7D8FA5; */
        /* background-color: #7D8FA5; */
        background-color: #181A22;
    }

    .album-item:hover {
        opacity: 0.2;
    }

    .album-name-div-albums {
        position: absolute;
        width: 15rem;
        height: 1.2rem;
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 164.286% */
        letter-spacing: 0.03125rem;
        margin-top: 0.3rem;
        margin-left: 1rem;
        color: #FFFFFF;
        font-weight: bold;
        font-style: normal;
        overflow: hidden;




    }

    .album-date-div-albums {
        position: absolute;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
        margin-top: 1.56rem;
        margin-left: 1rem;
    }

    .tag-div-albums1 {
        position: absolute;
        display: grid;
        /* margin-top: 1.69rem; */
        /* margin-left: 22.62rem; */
        grid-template-columns: 1fr 1fr;
        width: 2.1875rem;
        /* height: 0.9375rem; */
        height: 1.2rem;
        margin-left: 25.1rem;
        border-radius: 0.1875rem;
        /* background: #FFF; */
        margin-top: 1.16rem;
        /* align-items: center; */
        /* justify-content: center; */
        background-color: #7D8FA5;
    }

    .album-icon-albums1 {
        width: 0.5625rem;
        height: 0.5625rem;
        margin: 0.2rem;
        margin-left: 0.5rem;
        margin-top: 0.3rem;
    }

    .album-text-albums1 {
        color: #FFF;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 0.1rem;
        margin-left: 0.15rem;

    }

    .songs-flex-box-albums {

        display: flex;
        flex-direction: column;
        gap: 0.2rem;

    }

    .songs-item-albums {
        position: relative;
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.20);
        /* background: rgba(31, 56, 76, 0.20); */
        opacity: 1;
        background: #7D8FA5;
        width: 24rem;
        height: 3.625rem;


    }

    .songs-item-albums:hover {
        opacity: 0.2;
    }

    .song-image-albums {
        position: absolute;
        margin-left: 0.88rem;
        margin-top: 0.87rem;
        width: 1.875rem;
        height: 1.875rem;
    }

    .song-name-albums {
        position: absolute;
        margin-left: 3.38rem;
        margin-top: 0.56rem;
        /* width: 4.75rem; */
        height: 1.4375rem;
        color: #FFF;
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 164.286% */
        letter-spacing: 0.03125rem;
    }

    .artist-name-albums {
        position: absolute;
        color: #FFF;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
        /* width: 5.75rem; */
        height: 1.4375rem;
        margin-left: 3.38rem;
        margin-top: 1.62rem;

    }

    .time-tag-albums {
        position: absolute;
        margin-left: 18rem;
        margin-top: 1.12rem;
        color: #FFF;
        text-align: right;
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
    }

    .settings-icon-albums-album {
        position: absolute;
        margin-left: 30rem;
        margin-top: 1rem;
        width: 1.25rem;
        height: 1.5rem;
    }

    .settings-icon-albums-album:hover {
        cursor: pointer;
    }

    .edit-icon-display-album {
        position: absolute;
        margin-left: 28rem;
        margin-top: 1rem;
        width: 1.25rem;
        height: 1.5rem;
    }

    .edit-icon-display-album:hover {
        cursor: pointer;
    }

    .music-player-albums {
        position: relative;
        width: 20.5rem;
        height: 21rem;
        border-radius: 0.625rem;
        background: #7D8FA5;
    }

    .player-img-albums {
        position: absolute;
        width: 20.5rem;
        height: 15rem;
        background: url("./playerimg.png"), lightgray 50% / cover no-repeat;
        border-radius: 0.625rem 0.625rem 0 0;
    }

    .player-left-albums {
        position: absolute;
        width: 2.5rem;
        height: 2.5rem;
        margin-left: 0.87rem;
        margin-top: 17rem;
    }

    .player-middle-albums {
        position: absolute;
        width: 2.5rem;
        height: 2.5rem;
        margin-left: 8.5rem;
        margin-top: 17rem;
    }

    .player-right-albums {
        position: absolute;
        width: 2.5rem;
        height: 2.5rem;
        margin-left: 16.5rem;
        margin-top: 17rem;
    }

    .modal-main-div {
        position: relative;
        width: 25rem;
        height: 15rem;

        background: #FFF;



    }

    .modal-album-name {
        color: #1F384C;
        position: absolute;
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 143.75% */
        letter-spacing: 0.03125rem;
        margin-top: 17.94rem;
        margin-left: 24.56rem;
    }

    .modal-input {
        position: absolute;
        width: 19rem;
        height: 2.5rem;
        margin-left: 2rem;
        margin-top: 3rem;
        border-radius: 0.625rem;
        border-width: 0;
        opacity: 0.6;
        background: #7D8FA5;
        color: #ffffff;
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 164.286% */
        letter-spacing: 0.03125rem;
    }

    .modal-input::placeholder {
        color: #ffffff;
    }

    .modal-img-div {
        position: absolute;
        width: 10rem;
        height: 7rem;
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
        margin-left: 13.5rem;
        margin-top: 1.25rem;
        border-style: solid;
        border-color: #050505;

    }

    .modal-button {
        position: absolute;
        width: 6.25rem;
        height: 2.5rem;
        border-radius: 0.625rem;
        background: #7D8FA5;
        color: #FFF;
        opacity: 0.6;
        text-align: center;
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-width: 0;
        margin-top: 3rem;
        margin-left: 25rem;
    }

    .modal-button:hover {
        opacity: 2;
    }

    .modal-header-albums {
        position: absolute;
        width: 100%rem;
        height: 2rem;
        border-radius: 0.625rem 0.625rem 0rem 0rem;
        background: #7D8FA5;
    }

    .modal-header-text-albums {
        position: absolute;
        color: #f5f5f5;
        font-family: Roboto;
        font-size: 0.8em;
        font-style: normal;
        font-weight: 500;

        letter-spacing: 0.03125rem;
        margin-top: 0.5rem;
        margin-left: 1rem;
    }

    .modal-input-name-albums {
        margin-top: 4rem;
        margin-left: 6.3rem;
        position: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;

        line-height: 1.4375rem;
        letter-spacing: 0.03125rem;
    }

    .modal-input-name1-albums {
        margin-top: 4rem;
        margin-left: 10.5rem;
        position: absolute;
        color: #1F384C;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;

        line-height: 1.4375rem;
        letter-spacing: 0.03125rem;
    }

    .modal-input-name1-albums-song {
        margin-top: 3rem;
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        position: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        line-height: 1.4375rem;
        letter-spacing: 0.03125rem;
    }

    .modal-input-name1-albums-song1 {
        margin-top: 5rem;
        position: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        line-height: 1.4375rem;
        letter-spacing: 0.03125rem;
        font-weight: bold;
    }

    .modal-input-name1-albums-img {
        margin-top: 4rem;
        margin-left: 7.5rem;
        position: absolute;
        color: #1F384C;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;

        line-height: 1.4375rem;
        letter-spacing: 0.03125rem;
    }

    .modal-input-field-albums {
        position: absolute;
        width: 12rem;
        height: 1.7rem;
        border-radius: 0.2rem;

        background-color: rgba(125, 143, 165, 0.3);
        color: #FAFAFA;
        margin-top: 7rem;
        margin-left: 3rem;
        border-width: 0rem;
        outline-width: 0rem;

    }

    .modal-input-upload-albums {
        position: absolute;
        width: 13rem;
        height: 1.7rem;
        border-radius: 0.2rem;

        background-color: rgba(125, 143, 165, 0.3);
        color: #070707;
        margin-top: 7rem;
        margin-left: 3rem;
        border-width: 0rem;
        outline-width: 0rem;

    }

    /* .modal-input-field:placeholder{
    color: #050505;
} */
    .modal-preview-img {
        position: absolute;

    }

    .modal-input-field1 {
        position: absolute;
        width: 19rem;
        height: 2rem;
        border-radius: 0.625rem;
        opacity: 0.2;
        background: #7D8FA5;
        margin-top: 14rem;
        margin-left: 9rem;
    }

    .modal-button-next-albums {
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;
        background: rgb(252, 252, 252);
        opacity: 0.9;
        margin-top: 10rem;
        margin-left: 10rem;
        border-width: 0rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next-albums:hover {
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-button-next2-albums {
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;
        background: rgb(252, 252, 252);
        opacity: 0.9;
        margin-top: 10rem;
        margin-left: 4rem;
        border-width: 0rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next2-albums:hover {
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-button-next2-upload {
        /* position: absolute;
    width: 6.25rem;
height: 2.5rem;
border-radius: 0.625rem;
background: rgb(252, 252, 252);
opacity: 0.9;
margin-top:28rem;
margin-left: 15.5rem;
border-width: 0rem;
color: #7D8FA5;
text-align: center;
 font-family: Roboto;
font-size: 0.7rem;
font-style: normal;
font-weight: 700;
line-height: normal; */
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;
        background: rgb(252, 252, 252);
        opacity: 0.9;
        margin-top: 10.5rem;
        margin-left: 4rem;
        border-width: 0rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next2-upload:hover {
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-button-next2-update {
        /* position: absolute;
    width: 6.25rem;
height: 2.5rem;
border-radius: 0.625rem;
background: rgb(252, 252, 252);
opacity: 0.9;
margin-top:28rem;
margin-left: 15.5rem;
border-width: 0rem;
color: #7D8FA5;
text-align: center;
 font-family: Roboto;
font-size: 0.7rem;
font-style: normal;
font-weight: 700;
line-height: normal; */
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;
        background: rgb(252, 252, 252);
        opacity: 0.9;
        margin-top: 21rem;
        margin-left: 4rem;
        border-width: 0rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next2-update:hover {
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-button-next5-albums {
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;
        background: rgb(252, 252, 252);
        opacity: 0.9;
        margin-top: 10rem;
        margin-left: 4rem;
        border-width: 0rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next5-albums:hover {
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-upload-button {
        position: absolute;
        margin-top: 15rem;
        margin-left: 15rem;

    }

    .modal-input-name1-albums {
        /* margin-top:8rem;
    margin-left:15.5rem;
    position: absolute;
    color: #1F384C;
     font-family: Roboto;
    font-size: 1rem;
    font-style: normal;

    
    line-height: 1.4375rem;
    letter-spacing: 0.03125rem; */
        margin-top: 4rem;
        margin-left: 10.5rem;
        position: absolute;
        color: #1F384C;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;

        line-height: 1.4375rem;
        letter-spacing: 0.03125rem;
    }

    .modal-button-next1-upload {
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;

        opacity: 0.9;
        margin-top: 10.5rem;
        margin-left: 11rem;
        border-width: 0rem;
        background: #ffffff;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next1-upload:hover {
        /* opacity: 0.2; */
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-button-next1-update {
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;

        opacity: 0.9;
        margin-top: 21rem;
        margin-left: 11rem;
        border-width: 0rem;
        background: #ffffff;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next1-update:hover {
        /* opacity: 0.2; */
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-button-next4-albums {
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;

        opacity: 0.9;
        margin-top: 10rem;
        margin-left: 11rem;
        border-width: 0rem;
        background: #ffffff;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next4-albums:hover {
        /* opacity: 0.2; */
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-input-file {
        position: absolute;
        width: 20rem;
        height: 12rem;
        border-radius: 0.625rem;
        opacity: 0;
        background: #E5E9ED;
        margin-left: 9rem;
        margin-top: 12rem;
        /* display: none; */
        /* display: none; */

    }

    .modal-input-file1 {
        position: absolute;
        width: 20rem;
        height: 12rem;
        border-radius: 0.625rem;
        opacity: 0.2;
        background: #7D8FA5;
        margin-left: 9rem;
        margin-top: 12rem;
        /* display: none; */
        /* display: none; */

    }

    .modal-input-upload {
        position: absolute;
        margin-left: 17.5rem;
        margin-top: 16rem;
    }

    .modal-input-upload-text {

        width: 8.25rem;
        position: absolute;
        margin-left: 15rem;
        margin-top: 20rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem;
        /* 142.857% */
        letter-spacing: 0.03125rem;
    }

    .modal-input-upload-preview {
        position: absolute;
        width: 20rem;
        height: 12rem;
        margin-left: 9rem;
        margin-top: 12rem;
    }

    .spinner-album {
        margin-left: 25rem;
        margin-top: 10rem;
    }

    .modal-album-songupload {
        position: absolute;
        margin-top: 8rem;
        margin-left: 3.5rem;
        border: solid;
        background: #292D34;
        color: #FAFAFA;
        border-width: 0rem;
        width: 12rem;
        outline-width: 0;
    }

    .modal-album-artisttext {
        position: absolute;
        margin-top: 5.5rem;
        margin-left: 3.5rem;
        border: solid;
        border-radius: 0.2rem;
        color: #FAFAFA;
        background: #292D34;
        width: 12rem;
        border-width: 0rem;
        height: 1.7rem;
        outline-width: 0;
    }

    .delete-icon-new-album {
        position: absolute;
        margin-left: 32rem;
        margin-top: 1rem;
        width: 1.25rem;
        height: 1.5rem;
        cursor: pointer;
    }

}

@media only screen and (min-width: 1281px) {
    .album-loader-spinner {

        margin-left: 8rem;
        margin-top: 5.5rem;
    }

    .modal-button-next1-update {
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;

        opacity: 0.9;
        margin-top: 21rem;
        margin-left: 11rem;
        border-width: 0rem;
        background: #ffffff;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next1-update:hover {
        /* opacity: 0.2; */
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-button-next2-update {
        /* position: absolute;
        width: 6.25rem;
    height: 2.5rem;
    border-radius: 0.625rem;
    background: rgb(252, 252, 252);
    opacity: 0.9;
    margin-top:28rem;
    margin-left: 15.5rem;
    border-width: 0rem;
    color: #7D8FA5;
    text-align: center;
     font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal; */
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;
        background: rgb(252, 252, 252);
        opacity: 0.9;
        margin-top: 21rem;
        margin-left: 4rem;
        border-width: 0rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next2-update:hover {
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-album-imageupdate {
        position: absolute;
        margin-top: 17rem;
        margin-left: 3.5rem;
        border: solid;

        background: #292D34;
        color: #FAFAFA;

        border-width: 0rem;
        width: 12rem;
        outline-width: 0;
    }


    .img-upload-preview-albums {
        position: absolute;
        width: 8rem;
        height: 8rem;
        margin-left: 5.5rem;
        margin-top: 8rem;
    }

    .modal-album-updatetext {
        position: absolute;
        margin-top: 5.5rem;
        margin-left: 3.5rem;
        border: solid;
        border-radius: 0.2rem;

        background: #E5E9ED;
        width: 12rem;
        border-width: 0rem;
        height: 1.7rem;
        outline-width: 0;
    }


    .arrow-icon-albumns {
        position: absolute;
        margin-left: 54.5rem;
        margin-top: 1.45rem;
        width: 1rem;
        height: 0.7rem;
        cursor: pointer;
    }

    .modal-input-name1-albums-song {
        margin-top: 3rem;
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        position: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        line-height: 1.4375rem;
        letter-spacing: 0.03125rem;
    }

    .modal-input-name1-albums-song1 {
        margin-top: 5rem;
        position: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        line-height: 1.4375rem;
        letter-spacing: 0.03125rem;
        font-weight: bold;
    }

    .modal-button-next5-albums {
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;
        background: rgb(252, 252, 252);
        opacity: 0.9;
        margin-top: 10rem;
        margin-left: 4rem;
        border-width: 0rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next5-albums:hover {
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-button-next4-albums {
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;

        opacity: 0.9;
        margin-top: 10rem;
        margin-left: 11rem;
        border-width: 0rem;
        background: #ffffff;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next4-albums:hover {
        /* opacity: 0.2; */
        color: #FFF;
        background: #7D8FA5;
    }

    .colour-text-album {
        color: #000;
    }

    .header-grid-albums {
        margin-top: 1rem;
        margin-left: 1.5rem;
        display: grid;
        grid-template-columns: 5fr 1fr;
        padding-right: 2rem;
    }

    .header-text-albums {
        display: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 127.778% */
        letter-spacing: 0.03125rem;
    }

    .icons-grid-header-albums {
        display: grid;
        /* grid-template-columns: 2fr 1fr 1fr; */
        column-gap: 1rem;
    }

    .button-div-header-albums {
        margin-left: 9rem;
        display: absolute;
        width: 8rem;
        height: 1.875rem;
        background-color: #04CD18;
        border-radius: 0.24rem;
    }

    .button-text-div-albums {
        display: absolute;
        color: #FFF;
        text-align: center;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 0.75rem;
        /* 100% */
        letter-spacing: 0.03125rem;
        margin-left: 0.25rem;
        margin-top: 0.50rem;
        margin-right: 0.25rem;
        cursor: pointer;

    }

    .edit-button-div {
        width: 1.875rem;
        height: 1.875rem;

    }

    .content-div-albums {
        padding-left: 1rem;
        padding-top: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .content-grid-albums {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 1rem;


    }

    .album-flex-box-albums {

        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .album-item-albums {
        position: relative;
        width: 56rem;
        height: 3.5rem;
        border-radius: 0.625rem;

        background-color: #181A22;
    }

    /* .album-item-albums:hover{
    opacity: 0.2;
    } */
    .album-name-div-albums {
        position: absolute;
        height: 1.2rem;
        width: 21rem;
        font-family: Roboto;
        font-size: 0.875rem;

        line-height: 1.4375rem;
        /* 164.286% */
        letter-spacing: 0.03125rem;
        margin-top: 0.3rem;
        margin-left: 1rem;
        color: #FFFFFF;
        font-weight: bold;
        font-style: normal;
        overflow: hidden;




    }

    .album-date-div-albums {
        position: absolute;
        color: #FFFFFF;


        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
        margin-top: 1.56rem;
        margin-left: 1rem;
    }

    .tag-div-albums1 {
        position: absolute;
        display: grid;
        /* margin-top: 1.69rem; */
        /* margin-left: 22.62rem; */
        grid-template-columns: 1fr 1fr;
        width: 2.5rem;
        height: 1.2rem;
        margin-left: 45.7rem;
        border-radius: 0.1875rem;
        background-color: #7D8FA5;
        margin-top: 1.16rem;
        /* align-items: center; */
        /* justify-content: center; */
    }

    .album-icon-albums1 {
        width: 0.5625rem;
        height: 0.5625rem;
        margin: 0.2rem;
        margin-left: 0.5rem;
        margin-top: 0.3rem;
    }

    .album-text-albums1 {
        color: #FFF;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 0.1rem;
        margin-left: 0.15rem;
    }

    .songs-flex-box-albums {

        display: flex;
        flex-direction: column;
        gap: 0.2rem;

    }

    .songs-item-albums {
        position: relative;
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.20);
        /* background: rgba(31, 56, 76, 0.20); */
        opacity: 1;
        background: #7D8FA5;
        width: 24rem;
        height: 3.625rem;


    }

    .songs-item-albums:hover {
        opacity: 0.2;
    }

    .song-image-albums {
        position: absolute;
        margin-left: 0.88rem;
        margin-top: 0.87rem;
        width: 1.875rem;
        height: 1.875rem;
    }

    .song-name-albums {
        position: absolute;
        margin-left: 3.38rem;
        margin-top: 0.56rem;
        /* width: 4.75rem; */
        height: 1.4375rem;
        color: #FFF;
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 164.286% */
        letter-spacing: 0.03125rem;
    }

    .artist-name-albums {
        position: absolute;
        color: #FFF;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
        /* width: 5.75rem; */
        height: 1.4375rem;
        margin-left: 3.38rem;
        margin-top: 1.62rem;

    }

    .time-tag-albums {
        position: absolute;
        margin-left: 18rem;
        margin-top: 1.12rem;
        color: #FFF;
        text-align: right;
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
    }

    .settings-icon-albums-album {
        position: absolute;
        margin-left: 49rem;
        margin-top: 1rem;
        width: 1.25rem;
        height: 1.5rem;
    }

    .settings-icon-albums-album:hover {
        cursor: pointer;
    }

    .edit-icon-display-album {
        position: absolute;
        margin-left: 51rem;
        margin-top: 1rem;
        width: 1.25rem;
        height: 1.5rem;
    }

    .edit-icon-display-album {
        cursor: pointer;
    }

    .add-icon-display-album {
        position: absolute;
        margin-left: 84rem;
        margin-top: 0.9rem;
        width: 2.5rem;
        height: 1.7rem;
    }

    .edit-icon-display-album:hover {
        cursor: pointer;
    }

    .music-player-albums {
        position: relative;
        width: 20.5rem;
        height: 21rem;
        border-radius: 0.625rem;
        background: #7D8FA5;
    }

    .player-img-albums {
        position: absolute;
        width: 20.5rem;
        height: 15rem;
        background: url("./playerimg.png"), lightgray 50% / cover no-repeat;
        border-radius: 0.625rem 0.625rem 0 0;
    }

    .player-left-albums {
        position: absolute;
        width: 2.5rem;
        height: 2.5rem;
        margin-left: 0.87rem;
        margin-top: 17rem;
    }

    .player-middle-albums {
        position: absolute;
        width: 2.5rem;
        height: 2.5rem;
        margin-left: 8.5rem;
        margin-top: 17rem;
    }

    .player-right-albums {
        position: absolute;
        width: 2.5rem;
        height: 2.5rem;
        margin-left: 16.5rem;
        margin-top: 17rem;
    }

    .modal-main-div {
        position: relative;
        width: 25rem;
        height: 15rem;

        background: #FFF;



    }

    .modal-album-name {
        color: #1F384C;
        position: absolute;
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 143.75% */
        letter-spacing: 0.03125rem;
        margin-top: 17.94rem;
        margin-left: 24.56rem;
    }

    .modal-input {
        position: absolute;
        width: 19rem;
        height: 2.5rem;
        margin-left: 2rem;
        margin-top: 3rem;
        border-radius: 0.625rem;
        border-width: 0;
        opacity: 0.6;
        background: #7D8FA5;
        color: #ffffff;
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 164.286% */
        letter-spacing: 0.03125rem;
    }

    .modal-input::placeholder {
        color: #ffffff;
    }

    .modal-img-div {
        position: absolute;
        width: 10rem;
        height: 7rem;
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
        margin-left: 13.5rem;
        margin-top: 1.25rem;
        border-style: solid;
        border-color: #050505;

    }

    .modal-button {
        position: absolute;
        width: 6.25rem;
        height: 2.5rem;
        border-radius: 0.625rem;
        background: #7D8FA5;
        color: #FFF;
        opacity: 0.6;
        text-align: center;
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-width: 0;
        margin-top: 3rem;
        margin-left: 25rem;
    }

    .modal-button:hover {
        opacity: 2;
    }

    .modal-header-albums {
        /* position: absolute;
        width: 37.5rem;
    height: 2.625rem;
    border-radius: 0.625rem 0.625rem 0rem 0rem;
    background: #7D8FA5; */
        position: absolute;
        width: 100%;
        height: 2rem;
        border-radius: 0.625rem 0.625rem 0rem 0rem;
        background: #7D8FA5;
    }

    .modal-header-text-albums {
        /* position: absolute;
        color: #f5f5f5;
    font-family: Roboto;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    letter-spacing: 0.03125rem;
    margin-left:1rem; */
        position: absolute;
        color: #f5f5f5;
        font-family: Roboto;
        font-size: 0.8em;
        font-style: normal;
        font-weight: 500;

        letter-spacing: 0.03125rem;
        margin-top: 0.5rem;
        margin-left: 1rem;
    }

    .modal-input-name-albums {
        /* margin-top:12rem;
        margin-left:14.5rem;
        position: absolute;
        color: #1F384C;
         font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
       
        line-height: 1.4375rem; 
        letter-spacing: 0.03125rem; */
        margin-top: 4rem;
        margin-left: 6.3rem;
        position: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;

        line-height: 1.4375rem;
        letter-spacing: 0.03125rem;
    }

    .modal-input-field-albums {
        /* position: absolute;
        width: 19rem;
    height: 3rem;
    border-radius: 0.625rem;
    
    background: #E5E9ED;

    margin-top:15rem;
    margin-left:9rem;
    border-width: 0rem;
   
    color: #1F384C; */
        position: absolute;
        width: 12rem;
        height: 1.7rem;
        border-radius: 0.2rem;

        background-color: rgba(125, 143, 165, 0.3);
        color: #FAFAFA;
        margin-top: 7rem;
        margin-left: 3rem;
        border-width: 0rem;
        outline-width: 0rem;
    }

    .modal-preview-img {
        position: absolute;

    }

    .modal-input-field1 {
        position: absolute;
        width: 19rem;
        height: 2rem;
        border-radius: 0.625rem;
        opacity: 0.2;
        background: #7D8FA5;
        margin-top: 14rem;
        margin-left: 9rem;
    }

    .modal-button-next-albums {
        /* position: absolute;
        width: 6.25rem;
    height: 2.5rem;
    border-radius: 0.625rem;
    background: rgb(252, 252, 252);
    opacity: 0.9;
    margin-top:24rem;
    margin-left: 15.5rem;
    border-width: 0rem;
    color: #7D8FA5;
    text-align: center;
     font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal; */
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;
        background: rgb(252, 252, 252);
        opacity: 0.9;
        margin-top: 10rem;
        margin-left: 10rem;
        border-width: 0rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next-albums:hover {
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-button-next2-albums {
        /* position: absolute;
        width: 6.25rem;
    height: 2.5rem;
    border-radius: 0.625rem;
    background: rgb(252, 252, 252);
    opacity: 0.9;
    margin-top:28rem;
    margin-left: 15.5rem;
    border-width: 0rem;
    color: #7D8FA5;
    text-align: center;
     font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal; */
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;
        background: rgb(252, 252, 252);
        opacity: 0.9;
        margin-top: 10rem;
        margin-left: 4rem;
        border-width: 0rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next2-albums:hover {
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-button-next2-upload {
        /* position: absolute;
        width: 6.25rem;
    height: 2.5rem;
    border-radius: 0.625rem;
    background: rgb(252, 252, 252);
    opacity: 0.9;
    margin-top:28rem;
    margin-left: 15.5rem;
    border-width: 0rem;
    color: #7D8FA5;
    text-align: center;
     font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal; */
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;
        background: rgb(252, 252, 252);
        opacity: 0.9;
        margin-top: 10.5rem;
        margin-left: 4rem;
        border-width: 0rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next2-upload:hover {
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-upload-button {
        position: absolute;
        margin-top: 15rem;
        margin-left: 15rem;

    }

    .modal-input-name1-albums {
        /* margin-top:8rem;
        margin-left:15.5rem;
        position: absolute;
        color: #1F384C;
         font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        
        line-height: 1.4375rem; 
        letter-spacing: 0.03125rem; */
        margin-top: 4rem;
        margin-left: 10.5rem;
        position: absolute;
        color: #1F384C;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;

        line-height: 1.4375rem;
        letter-spacing: 0.03125rem;
    }

    .modal-input-name1-albums-img {
        /* margin-top:8rem;
        margin-left:15.5rem;
        position: absolute;
        color: #1F384C;
         font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        
        line-height: 1.4375rem; 
        letter-spacing: 0.03125rem; */
        margin-top: 4rem;
        margin-left: 7.5rem;
        position: absolute;
        color: #1F384C;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;

        line-height: 1.4375rem;
        letter-spacing: 0.03125rem;
    }

    .modal-button-next1-upload {
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;

        opacity: 0.9;
        margin-top: 10.5rem;
        margin-left: 11rem;
        border-width: 0rem;
        background: #ffffff;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next1-upload:hover {
        /* opacity: 0.2; */
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-input-file {
        position: absolute;
        width: 20rem;
        height: 12rem;
        border-radius: 0.625rem;
        opacity: 0;
        background: #7D8FA5;
        margin-left: 9rem;
        margin-top: 12rem;
        /* display: none; */
        /* display: none; */

    }

    .modal-input-file1 {
        position: absolute;
        width: 20rem;
        height: 12rem;
        border-radius: 0.625rem;
        opacity: 0.2;
        background: #7D8FA5;
        margin-left: 9rem;
        margin-top: 12rem;
        /* display: none; */
        /* display: none; */

    }

    .modal-input-upload-albums {
        position: absolute;
        width: 13rem;
        height: 1.7rem;
        border-radius: 0.2rem;

        background-color: rgba(125, 143, 165, 0.3);
        color: #070707;
        margin-top: 7rem;
        margin-left: 3rem;
        border-width: 0rem;
        outline-width: 0rem;
    }

    .modal-input-upload-preview {
        position: absolute;
        width: 20rem;
        height: 12rem;
        margin-left: 9rem;
        margin-top: 12rem;
    }

    .modal-input-upload-text {

        width: 8.25rem;
        position: absolute;
        margin-left: 15rem;
        margin-top: 20rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem;
        /* 142.857% */
        letter-spacing: 0.03125rem;
    }

    .spinner-album {
        margin-left: 45rem;
        margin-top: 18rem;
    }

    .modal-album-songupload {
        position: absolute;
        margin-top: 8rem;
        margin-left: 3.5rem;
        border: solid;
        background: #292D34;
        color: #FAFAFA;
        border-width: 0rem;
        width: 12rem;
        outline-width: 0;
    }

    .modal-album-artisttext {
        position: absolute;
        margin-top: 5.5rem;
        margin-left: 3.5rem;
        border: solid;
        border-radius: 0.2rem;
        color: #FAFAFA;
        background: #292D34;
        width: 12rem;
        border-width: 0rem;
        height: 1.7rem;
        outline-width: 0;
    }

    .delete-icon-new-album {
        position: absolute;
        margin-left: 53rem;
        margin-top: 1rem;
        width: 1.25rem;
        height: 1.5rem;
        cursor: pointer;

        /* position: absolute;
        margin-left: 32rem;
        margin-top: 1.1rem;
        width: 1.5rem;
        height: 1.2rem;
        cursor: pointer; */
    }

    .modal-new-create-img-album {
        position: absolute;
        margin-top: 16rem;
        margin-left: 12rem;
        background: #E5E9ED;
        color: #000;
    }
}