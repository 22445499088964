@media only screen and (max-width: 1280px) {

    .modal-button-next-webpages {
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;
        background: rgb(252, 252, 252);
        opacity: 0.9;
        margin-top: 14rem;
        margin-left: 13.5rem;
        border-width: 0rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next-webpages1 {
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;
        background: rgb(252, 252, 252);
        opacity: 0.9;
        margin-top: 10rem;
        margin-left: 13.5rem;
        border-width: 0rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next-webpages:hover {
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-button-next-webpages1:hover {
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-button-next2-webpages {
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;
        background: rgb(252, 252, 252);
        opacity: 0.9;
        margin-top: 14rem;
        margin-left: 7.5rem;
        border-width: 0rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next2-webpages1 {
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;
        background: rgb(252, 252, 252);
        opacity: 0.9;
        margin-top: 10rem;
        margin-left: 7.5rem;
        border-width: 0rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next2-webpages:hover {
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-button-next2-webpages1:hover {
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-input-field-webpages {
        position: absolute;
        width: 12rem;
        height: 1.7rem;
        border-radius: 0.2rem;

        background-color: rgba(125, 143, 165, 0.3);

        margin-top: 7rem;
        margin-left: 6.5rem;
        border-width: 0rem;
        outline-width: 0rem;

    }

    .modal-input-name-webpages {
        margin-top: 4rem;
        margin-left: 9rem;
        position: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;

        line-height: 1.4375rem;
        letter-spacing: 0.03125rem;
    }

    .modal-input-name-webpages2 {
        margin-top: 4rem;
        margin-left: 9.5rem;
        position: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;

        line-height: 1.4375rem;
        letter-spacing: 0.03125rem;
    }

    .modal-header-webpages {
        position: absolute;
        width: 24.78rem;
        height: 2rem;
        border-radius: 0.625rem 0.625rem 0rem 0rem;
        background: #7D8FA5;
    }

    .modal-header-text-webpages {
        position: absolute;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 0.8em;
        font-style: normal;
        font-weight: 500;

        letter-spacing: 0.03125rem;
        margin-top: 0.5rem;
        margin-left: 1rem;
    }

    .text-new-webpage {
        color: #000;
    }

    .modal-webpage-edit-text {
        position: absolute;
        margin-top: 15rem;
        margin-left: 12rem;
        border: solid;
        height: 1.5rem;
        border-radius: 0.625rem;
        /* opacity: 0.1; */
        color: #050505;
        background: #E5E9ED;
        width: 17rem;
        border-width: 0rem;
    }

    .header-grid-web {
        margin-top: 1rem;
        margin-left: 1.5rem;
        display: grid;
        grid-template-columns: 9fr 1fr;
        /* padding-right: 0.5rem; */
    }

    .header-text-web {
        display: absolute;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 127.778% */
        letter-spacing: 0.03125rem;
    }

    .icons-grid-header-web {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        column-gap: 1rem;
    }

    .button-div-header-web {
        display: absolute;
        width: 6.875rem;
        height: 1.875rem;
        background-color: #04CD18;
        border-radius: 0.24rem;
    }

    .button-text-div-web {
        display: absolute;
        color: #FFF;
        text-align: center;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 0.75rem;
        /* 100% */
        letter-spacing: 0.03125rem;
        margin-left: 0.25rem;
        margin-top: 0.50rem;
        margin-right: 0.25rem;
        cursor: pointer;

    }

    .edit-button-div-web {
        width: 1.875rem;
        height: 1.875rem;

    }

    .content-div-web {
        padding-left: 1.5rem;
        ;
        padding-top: 1.5rem;
    }

    .content-grid-web {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;


    }

    .album-flex-box-web {

        display: flex;
        flex-direction: column;
        gap: 0.2rem;
    }

    .album-item-web {
        position: relative;
        width: 30rem;
        height: 3.5rem;
        border-radius: 0.625rem;
        background-color: #181A22;

    }

    /* .album-item:hover{
opacity: 0.2;
} */
    .album-name-div-web {
        position: absolute;
        color: #FFFFFF;
        width: 15rem;
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: bold;
        line-height: 1.4375rem;
        /* 164.286% */
        letter-spacing: 0.03125rem;
        margin-top: 0.3rem;
        margin-left: 1rem;
        overflow: hidden;
        height: 1.2rem;



    }

    .album-date-div-web {
        position: absolute;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
        margin-top: 1.56rem;
        margin-left: 1rem;
        width: 15rem;
        overflow: hidden;
    }

    .tag-div-web {
        position: absolute;
        display: grid;
        /* margin-top: 1.69rem; */
        /* margin-left: 22.62rem; */
        grid-template-columns: 1fr 1fr;
        width: 2.1875rem;
        height: 0.9375rem;
        margin-left: 15rem;
        border-radius: 0.1875rem;
        background: #FFF;
        margin-top: 1rem;
        /* align-items: center; */
        /* justify-content: center; */
    }

    .album-icon-web {
        width: 0.5625rem;
        height: 0.5625rem;
        margin: 0.2rem;
        margin-left: 0.2rem;
    }

    .album-text-web {
        color: #050505;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 0.1rem;


    }

    .songs-flex-box-web {

        display: flex;
        flex-direction: column;
        gap: 0.1rem;

    }

    .songs-item-web {
        position: relative;
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.20);
        /* background: rgba(31, 56, 76, 0.20); */
        opacity: 1;
        background: #7D8FA5;
        width: 18.7rem;
        height: 3.625rem;


    }

    .songs-item-web:hover {
        opacity: 0.2;
    }

    .song-image-web {
        position: absolute;
        width: 3rem;
        height: 1.875rem;
        margin-left: 0.87rem;
        margin-top: 0.88rem
    }

    .song-name-web {
        position: absolute;
        margin-left: 4.5rem;
        margin-top: 0.56rem;
        width: 7rem;
        height: 1.4375rem;
        color: #FFF;
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 164.286% */
        letter-spacing: 0.03125rem;
    }

    .artist-name-web {
        position: absolute;
        color: #FFF;
        font-family: Roboto;
        font-size: 0.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
        /* width: 5.75rem; */
        height: 1.4375rem;
        margin-left: 4.5rem;
        margin-top: 1.62rem;

    }

    .time-tag-web {
        position: absolute;
        margin-left: 16.62rem;
        margin-top: 1.12rem;
        color: #FFF;
        text-align: right;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
    }

    .webpage-delete-icon-new {
        position: absolute;
        margin-left: 26rem;
        margin-top: 1rem;
        color: #FFF;
        text-align: right;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
        width: 1.3rem;
        cursor: pointer;
    }

    .settings-icon-web {
        position: absolute;
        margin-left: 16.5rem;
        margin-top: 1.38rem;
    }

    .webpage-edit-icon-new {
        position: absolute;
        margin-left: 28rem;
        margin-top: 1rem;
        color: #FFF;
        text-align: right;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
        width: 1.3rem;
        cursor: pointer;
    }

    .modal-web {
        display: none;
        /* Hidden by default */
        position: fixed;
        /* Stay in place */
        z-index: 1;
        /* Sit on top */
        padding-top: 100px;
        /* Location of the box */
        left: 0;
        top: 0;
        width: 100%;
        /* Full width */
        height: 100%;
        /* Full height */
        overflow: auto;
        /* Enable scroll if needed */
        background-color: rgb(0, 0, 0);
        /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4);
        /* Black w/ opacity */
    }

    .webpage-preview-web {
        width: 18rem;
        height: 12.75rem;
        background: url("./webpreview.png"), lightgray 50% / cover no-repeat;
    }

    .modal-webpage-pageupload {
        /* position: absolute;
    margin-top:15rem;
    margin-left:12rem;
    border:solid;
    border-radius: 0.625rem;
 
    color: #050505;
    background: #E5E9ED;
    width: 17rem;
    border-width: 0rem; */
        position: absolute;
        width: 12rem;
        height: 1.7rem;
        border-radius: 0.2rem;

        background-color: rgba(125, 143, 165, 0.3);
        color: #000;
        margin-top: 10rem;
        margin-left: 6.5rem;
        border-width: 0rem;
        outline-width: 0rem;

    }

    .modal-webpage-pageupload1 {
        position: absolute;
        width: 12rem;
        height: 1.7rem;
        border-radius: 0.2rem;

        background-color: rgba(125, 143, 165, 0.3);
        color: #FAFAFA;
        margin-top: 7rem;
        margin-left: 6.5rem;
        border-width: 0rem;
        outline-width: 0rem;
    }

    .modal-input-name-webpages1 {
        margin-top: 4rem;
        margin-left: 10.5rem;
        position: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;

        line-height: 1.4375rem;
        letter-spacing: 0.03125rem;
    }
}

@media only screen and (min-width: 1281px) {
    .modal-input-name-webpages2 {
        margin-top: 4rem;
        margin-left: 9.5rem;
        position: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;

        line-height: 1.4375rem;
        letter-spacing: 0.03125rem;
    }

    .modal-webpage-pageupload {
        /* position: absolute;
        margin-top:15rem;
        margin-left:12rem;
        border:solid;
        border-radius: 0.625rem;
     
        color: #050505;
        background: #E5E9ED;
        width: 17rem;
        border-width: 0rem; */
        position: absolute;
        width: 12rem;
        height: 1.7rem;
        border-radius: 0.2rem;

        background-color: rgba(125, 143, 165, 0.3);

        margin-top: 10rem;
        margin-left: 6.5rem;
        border-width: 0rem;
        outline-width: 0rem;

    }

    .modal-webpage-pageupload1 {
        position: absolute;
        width: 12rem;
        height: 1.7rem;
        border-radius: 0.2rem;
        color: #FAFAFA;
        background-color: rgba(125, 143, 165, 0.3);

        margin-top: 7rem;
        margin-left: 6.5rem;
        border-width: 0rem;
        outline-width: 0rem;
    }

    .modal-button-next2-webpages {
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;
        background: rgb(252, 252, 252);
        opacity: 0.9;
        margin-top: 14rem;
        margin-left: 7.5rem;
        border-width: 0rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next2-webpages1 {
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;
        background: rgb(252, 252, 252);
        opacity: 0.9;
        margin-top: 10rem;
        margin-left: 7.5rem;
        border-width: 0rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next2-webpages:hover {
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-button-next2-webpages1:hover {
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-button-next-webpages {
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;
        background: rgb(252, 252, 252);
        opacity: 0.9;
        margin-top: 14rem;
        margin-left: 13.5rem;
        border-width: 0rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next-webpages1 {
        position: absolute;
        width: 4rem;
        height: 2rem;
        border-radius: 0.625rem;
        background: rgb(252, 252, 252);
        opacity: 0.9;
        margin-top: 10rem;
        margin-left: 13.5rem;
        border-width: 0rem;
        color: #7D8FA5;
        text-align: center;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-button-next-webpages:hover {
        color: #FFF;
        background: #7D8FA5;
    }

    .modal-button-next-webpages1:hover {
        color: #FFF;
        background: #7D8FA5;
    }


    .modal-input-field-webpages {
        position: absolute;
        width: 12rem;
        height: 1.7rem;
        border-radius: 0.2rem;

        background-color: rgba(125, 143, 165, 0.3);

        margin-top: 7rem;
        margin-left: 6.5rem;
        border-width: 0rem;
        outline-width: 0rem;

    }

    .modal-input-name-webpages {
        margin-top: 4rem;
        margin-left: 9rem;
        position: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;

        line-height: 1.4375rem;
        letter-spacing: 0.03125rem;
    }

    .modal-input-name-webpages1 {
        margin-top: 4rem;
        margin-left: 9.5rem;
        position: absolute;
        color: #FAFAFA;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;

        line-height: 1.4375rem;
        letter-spacing: 0.03125rem;
    }

    .modal-header-webpages {
        position: absolute;
        width: 24.78rem;
        height: 2rem;
        border-radius: 0.625rem 0.625rem 0rem 0rem;
        background: #7D8FA5;
    }

    .modal-header-text-webpages {
        position: absolute;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 0.8em;
        font-style: normal;
        font-weight: 500;

        letter-spacing: 0.03125rem;
        margin-top: 0.5rem;
        margin-left: 1rem;
    }

    .text-new-webpage {
        color: #000;
    }

    .modal-webpage-edit-text {
        position: absolute;
        margin-top: 15rem;
        margin-left: 12rem;
        border: solid;
        border-radius: 0.625rem;
        /* opacity: 0.1; */
        color: #050505;
        height: 2rem;
        background: #E5E9ED;
        width: 17rem;
        border-width: 0rem;
    }

    .webpage-edit-icon-new {
        position: absolute;
        margin-left: 43rem;
        margin-top: 0.8rem;
        color: #FFF;
        text-align: right;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
        cursor: pointer;
    }


    .webpage-delete-icon-new {
        position: absolute;
        margin-left: 46rem;
        margin-top: 0.8rem;
        color: #FFF;
        text-align: right;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
        cursor: pointer;
    }

    .header-grid-web {
        margin-top: 1rem;
        margin-left: 1.5rem;
        display: grid;
        grid-template-columns: 9fr 1fr;
        /* padding-right: 2rem; */
    }

    .header-text-web {
        display: absolute;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 127.778% */
        letter-spacing: 0.03125rem;
    }

    .icons-grid-header-web {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        column-gap: 1rem;
    }

    .button-div-header-web {
        display: absolute;
        width: 6.875rem;
        height: 1.875rem;
        background-color: #04CD18;
        border-radius: 0.24rem;
    }

    .button-text-div-web {
        display: absolute;
        color: #FFF;
        text-align: center;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 0.75rem;
        /* 100% */
        letter-spacing: 0.03125rem;
        margin-left: 0.25rem;
        margin-top: 0.50rem;
        margin-right: 0.25rem;
        cursor: pointer;

    }

    .edit-button-div-web {
        width: 1.875rem;
        height: 1.875rem;

    }

    .content-div-web {
        padding-left: 1.5rem;
        ;
        padding-top: 1.5rem;
    }

    .content-grid-web {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;


    }

    .album-flex-box-web {

        display: flex;
        flex-direction: column;
        gap: 0.2rem;
    }

    .album-item-web {
        position: relative;
        width: 50rem;
        height: 3.5rem;
        border-radius: 0.625rem;
        background-color: #181A22;
    }

    /* .album-item:hover{
    opacity: 0.2;
    } */
    .album-name-div-web {
        position: absolute;

        width: 25rem;
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: bold;
        line-height: 1.4375rem;
        /* 164.286% */
        letter-spacing: 0.03125rem;
        margin-top: 0.3rem;
        margin-left: 1rem;
        color: #FFFFFF;
        overflow: hidden;
        height: 1.2rem;


    }

    .album-date-div-web {
        width: 25rem;
        overflow: hidden;
        position: absolute;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
        margin-top: 1.56rem;
        margin-left: 1rem;
    }

    .tag-div-web {
        position: absolute;
        display: grid;
        /* margin-top: 1.69rem; */
        /* margin-left: 22.62rem; */
        grid-template-columns: 1fr 1fr;
        width: 2.1875rem;
        height: 0.9375rem;
        margin-left: 15rem;
        border-radius: 0.1875rem;
        background: #FFF;
        margin-top: 1rem;
        /* align-items: center; */
        /* justify-content: center; */
    }

    .album-icon-web {
        width: 0.5625rem;
        height: 0.5625rem;
        margin: 0.2rem;
        margin-left: 0.2rem;
    }

    .album-text-web {
        color: #050505;
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 0.1rem;


    }

    .songs-flex-box-web {

        display: flex;
        flex-direction: column;
        gap: 0.1rem;

    }

    .songs-item-web {
        position: relative;
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.20);
        /* background: rgba(31, 56, 76, 0.20); */
        opacity: 1;
        background: #7D8FA5;
        width: 18.7rem;
        height: 3.625rem;


    }

    .songs-item-web:hover {
        opacity: 0.2;
    }

    .song-image-web {
        position: absolute;
        width: 3rem;
        height: 1.875rem;
        margin-left: 0.87rem;
        margin-top: 0.88rem
    }

    .song-name-web {
        position: absolute;
        margin-left: 4.5rem;
        margin-top: 0.56rem;
        width: 7rem;
        height: 1.4375rem;
        color: #FFF;
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 164.286% */
        letter-spacing: 0.03125rem;
    }

    .artist-name-web {
        position: absolute;
        color: #FFF;
        font-family: Roboto;
        font-size: 0.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
        /* width: 5.75rem; */
        height: 1.4375rem;
        margin-left: 4.5rem;
        margin-top: 1.62rem;

    }

    .time-tag-web {
        position: absolute;
        margin-left: 16.62rem;
        margin-top: 1.12rem;
        color: #FFF;
        text-align: right;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4375rem;
        /* 191.667% */
        letter-spacing: 0.03125rem;
    }

    .settings-icon-web {
        position: absolute;
        margin-left: 16.5rem;
        margin-top: 1.38rem;
    }

    .modal-web {
        display: none;
        /* Hidden by default */
        position: fixed;
        /* Stay in place */
        z-index: 1;
        /* Sit on top */
        padding-top: 100px;
        /* Location of the box */
        left: 0;
        top: 0;
        width: 100%;
        /* Full width */
        height: 100%;
        /* Full height */
        overflow: auto;
        /* Enable scroll if needed */
        background-color: rgb(0, 0, 0);
        /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4);
        /* Black w/ opacity */
    }

    .webpage-preview-web {
        width: 18rem;
        height: 12.75rem;
        background: url("./webpreview.png"), lightgray 50% / cover no-repeat;
    }

}