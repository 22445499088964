@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;

}

/* .app {
  display: grid;
 grid-template-columns: 1fr 4fr;
 grid-template-columns: 1fr 6fr;
  position: relative;
  height: 100vh;
} */
.app{
  display: flex;
  /* grid-template-columns: 1fr 4fr; */
  /* grid-template-columns: 1fr 6fr; */
   position: relative;
   height: 100vh;
   
}
.sidebarexample{
  position: sticky;
  top:0;
  height: 100vh;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
