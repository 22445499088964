.header-grid{
    margin-top:1rem;
    margin-left: 1.5rem;
    display:grid;
    grid-template-columns: 5fr 1fr;
    padding-right: 2rem;
}
.header-text{
    display:absolute;
    color: #1F384C;
font-family: SF Pro Display;
font-size: 1.125rem;
font-style: normal;
font-weight: 500;
line-height: 1.4375rem; /* 127.778% */
letter-spacing: 0.03125rem;
}