.header-grid-acc {
    margin-top: 1rem;
    margin-left: 1.5rem;
    display: grid;
    grid-template-columns: 15fr 1fr;
    padding-right: 2rem;
}

.header-text-acc {
    display: absolute;
    color: #FAFAFA;
    font-family: SF Pro Display;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    /* 127.778% */
    letter-spacing: 0.03125rem;
}

.content-div-acc {
    padding-left: 1.5rem;
    ;
    padding-top: 1.5rem;
}

.content-grid-acc {
    display: grid;
    grid-template-columns: 1fr 2fr;


}

.acc-flex-box {
    display: flex;
    position: relative;
    flex-direction: row;
    gap: 1.87rem;
}

.acc-rectangle {
    position: relative;
    width: 15.625rem;
    height: 15.625rem;
    border-radius: 0.625rem;
    margin-left: 17rem;

}

.acc-about {
    position: relative;
    width: 15.625rem;
    height: 15.625rem;
}

.acc-text {
    position: absolute;
    color: #7D8FA5;
    font-family: SF Pro Display;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.75rem;
    /* 85.714% */
}

input[type="number"]::-webkit-outer-spin-button,

input[type="number"]::-webkit-inner-spin-button {

    -webkit-appearance: none;

    margin: 0;
}

.acc-description {
    display: absolute;
    width: 14.75rem;

    color: #1F384C;
    font-family: SF Pro Display;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    /* 180% */
    margin-top: 2rem;
}

.acc-deatils-flex {
    display: flex;
    flex-direction: column;
}

.flex-item1 {
    width: 18.625rem;
    height: 10rem;
    position: relative;
}

.acc-name-user {
    position: absolute;
    color: #FAFAFA;
    font-family: SF Pro Display;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4375rem;
    /* 88.462% */
    letter-spacing: 0.03125rem;
}

.acc-contact-info {
    position: absolute;
    color: #7D8FA5;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.75rem;
    /* 85.714% */
    margin-top: 3rem;
}

.acc-line {
    margin-top: 3.5rem;
    margin-left: 8rem;
    width: 12.5625rem;
    height: 0.0625rem;
    background: #CED6DD;
}

.acc-phone-number-side {
    margin-top: 2rem;
    color: #FAFAFA;
    font-family: Roboto;
    font-size: 0.8rem;
    font-style: normal;
    /* font-weight: 700; */
    line-height: 1.125rem;
    /* 180% */
}

.acc-phone-role-side {
    margin-top: 2rem;
    color: #FAFAFA;
    font-family: Roboto;
    font-size: 0.8rem;
    font-style: normal;
    /* font-weight: 700; */
    line-height: 1.125rem;
    /* 180% */
}

.acc-profile-pic-rectangle {
    border-radius: 0.625rem;
    /* opacity: 0.2; */
    background: #7D8FA5;
    position: absolute;
    width: 15.625rem;
    height: 15.625rem;
    flex-shrink: 0;
}

.grid-button-edit {
    /* position: absolute; */
    width: 2rem;
    height: 1.5rem;

}

.grid-button-edit:hover {
    cursor: pointer;

}

.modal-header-acc {
    position: absolute;
    width: 100%;
    height: 2rem;
    border-radius: 0.625rem 0.625rem 0rem 0rem;
    background: #7D8FA5;
}

.modal-header-text-acc {
    position: absolute;
    color: #FAFAFA;
    font-family: Roboto;
    font-size: 0.8em;
    font-style: normal;
    font-weight: 500;

    letter-spacing: 0.03125rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
}

.modal-input-name1-albums-song-acc {
    margin-top: 3rem;
    margin-left: 7rem;
    position: absolute;
    color: #FAFAFA;
    font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    line-height: 1.4375rem;
    letter-spacing: 0.03125rem;
}

.img-upload-preview-albums-acc {
    position: absolute;
    width: 8rem;
    height: 8rem;
    margin-left: 5.5rem;
    margin-top: 11rem;
}

.modal-album-imageupdate-acc {
    position: absolute;
    margin-top: 20rem;
    margin-left: 3.5rem;
    border: solid;
    background: #292D34;
    color: #FAFAFA;
    border-width: 0rem;
    width: 12rem;
    outline-width: 0;
}

.modal-album-updatetext-acc {
    position: absolute;
    margin-top: 5.5rem;
    margin-left: 3.5rem;
    border: solid;
    border-radius: 0.2rem;
    color: #FAFAFA;
    background: #292D34;
    width: 12rem;
    border-width: 0rem;
    height: 1.7rem;
    outline-width: 0;
}

.modal-button-next1-update-acc {
    position: absolute;
    width: 4rem;
    height: 2rem;
    border-radius: 0.625rem;

    opacity: 0.9;
    margin-top: 24rem;
    margin-left: 11rem;
    border-width: 0rem;
    background: #ffffff;
    color: #7D8FA5;
    text-align: center;
    font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.modal-button-next1-update-acc:hover {
    /* opacity: 0.2; */
    color: #FFF;
    background: #7D8FA5;
}

.modal-button-next2-update-acc {
    /* position: absolute;
    width: 6.25rem;
height: 2.5rem;
border-radius: 0.625rem;
background: rgb(252, 252, 252);
opacity: 0.9;
margin-top:28rem;
margin-left: 15.5rem;
border-width: 0rem;
color: #7D8FA5;
text-align: center;
 font-family: Roboto;
font-size: 0.7rem;
font-style: normal;
font-weight: 700;
line-height: normal; */
    position: absolute;
    width: 4rem;
    height: 2rem;
    border-radius: 0.625rem;
    background: rgb(252, 252, 252);
    opacity: 0.9;
    margin-top: 24rem;
    margin-left: 4rem;
    border-width: 0rem;
    color: #7D8FA5;
    text-align: center;
    font-family: Roboto;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.modal-button-next2-update-acc:hover {
    color: #FFF;
    background: #7D8FA5;
}

.modal-album-updatetext-acc1 {
    position: absolute;
    margin-top: 8rem;
    margin-left: 3.5rem;
    border: solid;
    border-radius: 0.2rem;
    color: #FAFAFA;
    background: #292D34;
    width: 12rem;
    border-width: 0rem;
    height: 1.7rem;
    outline-width: 0;
}