/* .grid-box{
display: grid;
grid-template-columns: 1fr 1fr;
}
.grid-div1{
    height: 860px;
    background-color: #7D8FA5;
    }

.smart-vinyl{
    margin-top: 18.06rem;
    margin-right:  27.81rem;;
    
    margin-left: 4.5rem;
}   
    .grid-div2{
        background-color: #E2E7EB;;
    height: 860px;
        }
        .login-grid{
            display: grid;
            grid-template-columns: 1fr;
            row-gap: 2rem;
        }
        .login-text{
            color: #7D8FA5;

font-family: SF Pro Display;
font-size: 1.75rem;
font-style: normal;
font-weight: 700;
line-height: normal;
        }
        .login-field{
            border-radius: 0.625rem;
background: #CFD8E1;

backdrop-filter: blur(50px);
        }
        .login-button{
            border-radius: 0.625rem;
background: #CFD8E1;

backdrop-filter: blur(50px);
        }
        .login-grid-wrapeer{
            margin-top: 15rem;
            margin-left: 17rem;

        }


.main-flex-div{
    display:flex ;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  height: 56rem;
}
.flex-element1{
    flex: 1;
margin-top: 8.31rem;
margin-left: 35rem;
margin-right: 35rem;
width: 25.9375rem;
height: 11.5625rem;
border-radius: 1.875rem 1.875rem 1.875rem 1.875rem;
background: rgba(0, 0, 0, 0.50);
}
.grid-main
{
   display:grid;
 justify-items: center;
 align-items: center;
 margin-top: 8.31rem; 
   
 

}

.maindiv{
    width: 25.9375rem;
    height: 32.1875rem;
    border-radius: 1.875rem;
background: #FFF;
box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.20);
}
.div1{
    width: 25.9375rem;
height: 11.5625rem;


background-image: linear-gradient(rgba(0, 0, 0, 0.5),
                       rgba(0, 0, 0, 0.5)), url("./container.png");
background-size: cover;
border-radius: 1.875rem 1.875rem 0rem 0rem;
display: grid;
}
.div2{
    width: 25.9375rem;
  
}
.div3{
    width: 22rem;
    display: grid;
    grid-template-columns:3fr 1fr;
    border-radius: 1.125rem;
    border: 1px solid #858585;
  
    margin-left: 1.56rem;
    align-items: center;
}
.div4{
    width: 22rem;
    display: grid;
    grid-template-columns:3fr 1fr;
    border-radius: 1.125rem;
    border: 1px solid #858585;
 
    margin-left: 1.56rem;
    align-items: center;
    margin-top: 1.25rem;

}
.div5{
    width: 25.9375rem;

}
.div6{
    width: 25.9375rem;

}
.p1{
    margin-top: 0.62rem;
    margin-left: 1.56rem;
    margin-right: 18.38rem;
  
    color: #873483;
    
    font-family: Saira;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.inputuser{
 margin-left: 1rem;
 border: none;
 height: 3.125rem;
 outline-width: 0;
 color: #858585;

font-family: Poppins;
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}


.inputpass{
    margin-top: 1rem;
    margin-left: 1.56rem;
    margin-right: 1.56rem;
    width: 22.8125rem;
height: 3.125rem;
border-radius: 1.125rem;
border: 1px solid #858585;
background: #FFF;
}
.forgot{
    margin-top: 1rem;
    margin-left: 17.5rem;
    color: #873483;


font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.login-button{
    margin-top: 0.5rem;
    margin-left: 1.56rem;
    margin-right: 1.56rem;
    width: 22.8125rem;
height: 3.125rem;
border:none;
border-radius: 1.125rem;
background: #873483;
color: #FFF;

font-family: Poppins;
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: normal;

}
.business{
margin-top: 3.5rem;
margin-left: 1.56rem;
color: #FFF;
font-family: Poppins;
font-size: 0.85rem;
font-style: normal;
font-weight: 400;
line-height: 0.9375rem; 
width: 11.5rem;
height:1.875rem

}
.iconimg{
    width: 8.66413rem;
height: 2.53438rem;
margin-top: 2.26rem;
margin-left: 1.56rem;
}


 */
.main_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* justify-content: center; */
  height: 100vh;
}

.text_div {
  background: #000000;
  ;
  display: grid;
  background-image: url("./eclipse.png");
  background-position-x: right;
  background-position-y: center;
  background-repeat: no-repeat;
}

.smart_logo {
  margin-left: 4.5rem;
  margin-top: 13rem;
  width: 12.6875rem;
  height: 6.125rem;
}

.smart_text {
  margin-left: 4.5rem;

  color: #FFF;

  font-family: Roboto;
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 28rem;
  height: 9rem;
}

.login_div {
  background: #000000;
  display: grid;
  align-content: center;
}

.login_grid {
  /* display: grid; */
  /* grid-template-columns: 1fr ; */
  /* grid-template-rows: 1fr 1fr; */
  display: grid;
  /* margin-top:18rem;
margin-left:15rem; */
  /* width: 20rem; */
  width: 100vb;
  grid-template-rows: 5rem 2.5rem 2.5rem 2.5rem 2.5rem;
  justify-content: center;
  height: 100vh;
  align-content: center;
  row-gap: 1rem;
  /* grid-template-columns: 1fr; */



}

.login_text {
  margin-top: 8rem;
  margin-left: 10.37rem;
  color: #7D8FA5;
  width: 11.75rem;
  height: 4.125rem;
  font-family: Roboto;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.login_password_input {
  border: none;
  background: #181A22;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline-width: 0;
  color: #FAFAFA;
}

.login_details_user {
  border-radius: 0.3rem;
  border: none;
  background: #181A22;
  width: 20rem;
  height: 2.5rem;
  backdrop-filter: blur(50px);
  color: #FAFAFA;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline-width: 0;
}

::placeholder {
  color: white;
}

.login_deatils_password {
  border-radius: 0.3rem;
  border: none;
  background: #CFD8E1;
  color: #7D8FA5;
  width: 20rem;
  height: 2.5rem;
  backdrop-filter: blur(50px);

}

.login_password_grid {
  display: grid;
  grid-template-columns: 8fr 1fr;
  border-radius: 0.3rem;
  border: none;
  background: #181A22;
  width: 20rem;
  height: 2.5rem;
  backdrop-filter: blur(50px);
  color: #7D8FA5;
  align-items: center;
}


.forgot_pass {
  margin-top: 0.2rem;
  margin-left: 13rem;
  color: #FAFAFA;
  text-align: right;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login_button {
  width: 20rem;
  height: 3rem;
  border-radius: 0.625rem;
  background: #000000;
  border: 1px solid #292D34;
  color: #FFF;
  cursor: pointer;
  text-align: center;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* .inputusericon{
  color:#FAFAFA ;
 } */

.login_text_new {
  color: #FAFAFA;
  font-family: SF Pro Display;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 11.75rem;
}